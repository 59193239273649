import {
  INIT_REPLY_COUNT,
  UPDATE_POST_REPLY_COUNT,
  RESET_REPLY_COUNT,
} from "../constants/types";

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INIT_REPLY_COUNT:
      return {
        ...state,
        ...payload,
      };
    case RESET_REPLY_COUNT:
      return initialState;
    case UPDATE_POST_REPLY_COUNT:
      return {
        ...state,
        [parseInt(payload)]:
          state[parseInt(payload)] && state[parseInt(payload)] >= 0
            ? state[parseInt(payload)] + 1
            : 1,
      };
    default:
      return state;
  }
};
