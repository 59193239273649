import React from "react";
import useFirebaseFileLoader from "../../utils/FirebaseFileLoader";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import "./Tag.css";

export default function Tag({ setTaggedMember, tag }) {
  const id = tag?.id;
  const name = tag?.full_name;
  const url = tag?.photo_firebase_path;
  const [profileUrl, loading] = useFirebaseFileLoader(url);

  return (
    <MenuItem>
      <div className="tag" onClick={() => setTaggedMember(tag)}>
        <Avatar src={profileUrl} />
        <span className="tag__name">{`${name} - ${id}`}</span>
      </div>
    </MenuItem>
  );
}
