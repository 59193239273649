import {
  OPEN_USER_DETAILS,
  CLOSE_USER_DETAILS,
  UPDATE_USER_DETAILS,
} from "../constants/types";
import { Get } from "../utils/api";
import APIConfig from "../utils/APIConfig";

export const fetchUserDetails = (userId) => (dispatch) => {
  dispatch(openUserDetails());
  Get(APIConfig.USER_PROFILE + `${userId}`)
    .then((res) => {
      dispatch({
        type: UPDATE_USER_DETAILS,
        payload: res,
      });
    })
    .catch((error) => console.error(error));
};

export const openUserDetails = () => (dispatch) => {
  dispatch({
    type: OPEN_USER_DETAILS,
    payload: true,
  });
};

export const closeUserDetails = () => (dispatch) => {
  dispatch({
    type: CLOSE_USER_DETAILS,
    payload: true,
  });
};
