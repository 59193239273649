import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { generateClapsBadge } from "../../utils/formatDate";
import { closeUserDetails } from "../../actions/user-details.action";
import useFirebaseFileLoader from "../../utils/FirebaseFileLoader";
import { sortBy } from "../../utils/formatDate";
import Spinner from "../dashboard/Spinner";
import PinDropIcon from "@material-ui/icons/PinDrop";
import "./UserInfo.css";
import { isMobile } from "../../utils/formatDate";

const modalWidth = isMobile() ? "100%" : 400;

function getModalStyle() {
  const top = 0;
  const right = 0;

  return {
    top: `${top}%`,
    right: `${right}%`,
    outline: "none",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    overflow: "auto",
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    position: "absolute",
    width: modalWidth,
    height: "100vh",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
}));

const UserInfo = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const userInfo = useSelector((state) => state.userInfo);
  const userDetails = userInfo?.userDetails;
  const profileUrlPath = userDetails?.photo_firebase_path;
  const [profileUrl, loading] = useFirebaseFileLoader(profileUrlPath);
  const experienceLevel = userDetails?.work_info?.experience_level?.id;
  const companyTitle = userDetails?.work_info?.company_title;
  const company = userDetails?.work_info?.company;
  const memberOfGroups = userDetails?.work_info?.categories?.sort(
    sortBy("name")
  );

  const handleClose = () => {
    dispatch(closeUserDetails());
  };

  return (
    <Modal open={userInfo?.isOpen} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className="userInfo__header">
          <span>USER ID - {userDetails?.id}</span>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {userInfo?.loading && <Spinner />}
        {!userInfo?.loading && (
          <div className="userInfo__content">
            <>
              <div className="userInfo__card">
                <div className="userInfo__cardProfile">
                  {!profileUrl && (
                    <div className="userInfo__imgContainer"></div>
                  )}
                  {profileUrl && (
                    <img
                      className="userInfo__Image"
                      src={profileUrl}
                      alt="user profile"
                      key={profileUrlPath}
                    />
                  )}
                </div>
                <div className="userInfo__details">
                  <div className="userInfo__name">
                    {userDetails?.full_name?.toUpperCase()}
                  </div>
                  <div className="userInfo__jobTitle">
                    {companyTitle && company
                      ? `${companyTitle} at ${company}`
                      : "Fresher"}
                  </div>
                  <br />
                  <div className="userInfo__education">
                    {`${userDetails?.work_info?.education_level?.level}${
                      experienceLevel ? ` | ${experienceLevel} yr. exp.` : ""
                    }`}
                  </div>
                  <div className="userInfo__phone">
                    {userDetails?.phone_number}
                  </div>
                  {userDetails?.profile?.city_obj?.name && (
                    <div className="userInfo__location">
                      <PinDropIcon />
                      {userDetails?.profile?.city_obj?.name}
                    </div>
                  )}
                </div>
              </div>
              <div className="userInfo__stats">
                <div className="userInfo__clap">
                  {generateClapsBadge(userDetails?.clap_details?.claps)}
                  <div className="userInfo__clapInfo">
                    <span>{userDetails?.clap_details?.claps} </span>
                    <span>Claps received</span>
                  </div>
                </div>
                <div className="userInfo__statDetails">
                  <div className="userInfo__count">
                    <span>{userDetails?.views}</span>
                    <span>views</span>
                  </div>
                  <div className="userInfo__count">
                    <span>{userDetails?.friends}</span>
                    <span>connections</span>
                  </div>
                  <div className="userInfo__count">
                    <span>{userDetails?.referrals}</span>
                    <span>referrals</span>
                  </div>
                </div>
              </div>
              <div className="userInfo__groupCaption">
                Member in these groups
                <hr />
              </div>

              <div className="userInfo__groupDetails">
                {memberOfGroups?.map((group, index) => (
                  <div className="userInfo__group" key={index}>
                    <img
                      className="userInfo__groupImage"
                      src={group?.photo_url}
                      alt=""
                    />
                    <span>{group?.name}</span>
                  </div>
                ))}
                <hr />
              </div>
            </>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UserInfo;
