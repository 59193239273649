import React from "react";
import Popover from "@material-ui/core/Popover";
import Tag from "../tag/Tag";
import { isMobile } from "../../utils/formatDate";

export default function FeedTagSuggestions({
  filteredList,
  inputRef,
  setTaggedMember,
  type,
  handlePopoverClose,
  showFeedTagSuggestions,
}) {
  const showMobileComponent = isMobile();
  const selectTaggedMember = (tag) => {
    setTaggedMember(tag);
    handlePopoverClose();
  };
  const renderTagSuggestions = () => {
    return (
      filteredList &&
      filteredList.map((tag) => (
        <Tag key={tag?.id} setTaggedMember={selectTaggedMember} tag={tag} />
      ))
    );
  };
  return (
    <>
      {showMobileComponent && showFeedTagSuggestions && (
        <div className="tag__suggestions">{renderTagSuggestions()}</div>
      )}
      {!showMobileComponent && (
        <Popover
          id=""
          open={showFeedTagSuggestions}
          onClose={handlePopoverClose}
          anchorEl={inputRef.current}
          anchorOrigin={{
            vertical: "top",
            horizontal: type === "thread" ? "left" : 550,
          }}
          disableAutoFocus
          disableEnforceFocus
          transformOrigin={{
            vertical: "top",
            horizontal: type === "thread" ? "right" : "left",
          }}
          PaperProps={{
            style: { maxHeight: 350 },
          }}
        >
          {renderTagSuggestions()}
        </Popover>
      )}
    </>
  );
}
