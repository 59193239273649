import React, { Component } from "react";
import { connect } from "react-redux";
import { storage } from "../../firebase";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import { IconButton } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MicIcon from "@material-ui/icons/Mic";
import NewPostImageInput from "./NewPostImageInput";
import NewPostVideoInput from "./NewPostVideoInput";
import NewPostFileInput from "./NewPostFileInput";
import NewPostAudioInput from "./NewPostAudioInput";
import NewPostMicInput from "./NewPostMicInput";
import {
  fetchTagSuggestions,
  closeTagSuggestions,
  fetchTagSuggestionsNew,
} from "../../actions/update-tag-suggestion.action";
import imageCompression from "browser-image-compression";
import "./MediaUploads.css";

class MediaUploads extends Component {
  state = {
    isMention: false,
    showTagSuggestions: false,
    tagSuggestionList: [],
    showFeedTagSuggestions: false,
    term: "",
    filteredList: [],
    taggedMembers: [],
    open: false,
    emojiPanel: false,
    openFileUploadModal: false,
    openImageUploadModal: false,
    openVideoUploadModal: false,
    openAudioUploadModal: false,
    openMicUploadModal: false,
    mediaFile: null,
    caption: "",
    status: "Please upload File.",
    type: "TEXT",
    loading: false,
    url: null,
    mediaFileName: "",
    fileSize: null,
    fileName: "",
  };
  inputRef = React.createRef();

  setAttachmentType = (mediaType) => {
    this.setState({ type: mediaType });
  };
  onKeyDown = (e) => {
    let lastCharEntered =
      e.target.value.charAt(e.target.value.length - 1) || "";
    if (e.key === "@" || lastCharEntered === "@") {
      this.setState({ isMention: true });
    }
  };

  setTaggedMember = (taggedMember) => {
    let previousText = this.state.caption;
    let lastIndex = previousText.lastIndexOf("@");
    this.setState({
      caption:
        previousText.substring(0, lastIndex) +
        "@" +
        taggedMember.full_name +
        " ",
    });
    let start = lastIndex;
    let end = start + taggedMember.full_name.length;
    this.setState({
      taggedMembers: this.state.taggedMembers.concat([
        {
          start: start,
          end: end,
          fullName: taggedMember.full_name,
          userId: taggedMember.id,
        },
      ]),
    });
    this.setState({ showTagSuggestions: false, isMention: false });
    this.props.closeTagSuggestions();
  };

  emojiPanelOpenClose = () => {
    this.setState({ emojiPanel: !this.state.emojiPanel });
  };
  onEmojiClick = (event, emojiObject) => {
    let newMessage = this.state.message + emojiObject.emoji;
    this.setState({ message: newMessage });
  };
  onCaptionEmojiClick = (event, emojiObject) => {
    let newCaption = this.state.caption + emojiObject.emoji;
    this.setState({ caption: newCaption });
  };
  setOpenFileUploadModal = (open) => {
    this.setState({
      emojiPanel: false,
      mediaFile: null,
      caption: "",
      status: "Please upload File.",
      openFileUploadModal: open,
    });
  };

  handleCaptionChange = (e) => {
    e.preventDefault();
    this.setState({ caption: e.target.value });
    const { isMention, taggedMembers } = this.state;
    const groupId =
      this.props.selectedFeed?.id || this.props.feedList?.selectedFeed?.id;
    this.setState({ showTagSuggestions: false });
    let check = e.target.value.lastIndexOf("@");
    let trimValue = e.target.value.trim();
    if (check > -1) {
      let matchingData = e.target.value.substring(check + 1, trimValue.length);
      let existingTagIndex = taggedMembers.indexOf(matchingData);
      if (matchingData.length <= 2 && isMention) {
        this.setState({
          term: matchingData,
          showFeedTagSuggestions: true,
          showTagSuggestions: false,
        });
        this.filterTags(matchingData);
      }
      if (
        matchingData &&
        matchingData.length >= 0 &&
        isMention &&
        existingTagIndex === -1
      ) {
        let params = {groupId: groupId, keyword: matchingData, limit: 30}
        if (this.props.type === "thread" && this.props.repliedPost && this.props.repliedPost.id) {
          params.postId = this.props.repliedPost.id;
        }
        this.props.fetchTagSuggestionsNew(params, this.props.selectedSeedUser?.id);
        this.setState({
          showTagSuggestions: true,
          term: "",
          showFeedTagSuggestions: false,
        });
      }
    } else {
      this.setState({
        showTagSuggestions: false,
        showFeedTagSuggestions: false,
      });
    }
  };
  filterTags = (matchingData) => {
    let filteredList = [];
    let term = matchingData;
    const { feedTagSuggestions, threadTagSuggestions } = this.props.tags;
    let tagList =
      threadTagSuggestions?.length > 0
        ? threadTagSuggestions
        : feedTagSuggestions;

    tagList &&
      tagList.forEach((tag) => {
        if (tag?.full_name?.toLowerCase().indexOf(term.toLowerCase()) !== -1) {
          filteredList.push(tag);
        }
      });
    if (term !== "") {
      this.setState({ filteredList: filteredList });
    } else {
      this.setState({ filteredList: tagList });
    }
  };

  handlePopoverClose = () => {
    this.setState({ showTagSuggestions: false, showFeedTagSuggestions: false });
    this.props.closeTagSuggestions();
  };
  handleAttachmentClick = (type) => {
    if (type === "IMAGE") {
      this.setState({ openImageUploadModal: true });
    }
    if (type === "VIDEO") {
      this.setState({ openVideoUploadModal: true });
    }
    if (type === "AUDIO") {
      this.setState({ openAudioUploadModal: true });
    }
    if (type === "FILE") {
      this.setState({ openFileUploadModal: true });
    }
    if (type === "MIC") {
      this.setState({ openMicUploadModal: true });
    }
    this.setAttachmentType(type === "MIC" ? "audio" : type?.toLowerCase());
  };
  onCloseFileModal = () => {
    this.setState({
      openFileUploadModal: false,
      openImageUploadModal: false,
      openVideoUploadModal: false,
      openAudioUploadModal: false,
      openMicUploadModal: false,
      caption: "",
      mediaFile: null,
      url: null,
      mediaFileName: "",
      fileName: "",
      taggedMembers: [],
    });
  };
  handleRecordInput = async (inputFile) => {
    let mediaFileName =
      this.props.selectedSeedUser?.id + "_" + new Date().getTime();
    this.setState({ mediaFileName: mediaFileName, mediaFile: inputFile });
    setTimeout(this.handleUpload, 500);
  };
  handleFileInputChange = async (e) => {
    let options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    if (e.target.files[0]) {
      const inputFile = e.target.files[0];

      if (inputFile.type.includes("image")) {
        const compressedFile = await imageCompression(inputFile, options);
        this.setState({
          mediaFile: compressedFile,
          fileSize: compressedFile.size,
        });
      } else if (inputFile.type.includes("video")) {
        this.setState(() => ({ mediaFile: inputFile }));
        this.setState({ fileSize: inputFile.size });
        let myVideoPlayer = document.getElementById("player");
        if (myVideoPlayer !== null) {
          myVideoPlayer.addEventListener("loadedmetadata", () => {
            this.setState({ mediaDuration: myVideoPlayer.duration });
          });
        }
      } else if (inputFile.type.includes("audio")) {
        this.setState(() => ({ mediaFile: inputFile }));
        this.setState({ fileSize: inputFile.size });
        let myAudioPlayer = document.getElementById("audioPlayer");
        myAudioPlayer.addEventListener("loadedmetadata", () => {
          this.setState({ mediaDuration: myAudioPlayer.duration });
        });
      } else {
        this.setState(() => ({ mediaFile: inputFile }));
        this.setState({ fileSize: inputFile.size, fileName: inputFile.name });
      }

      let mediaFileName =
        this.props.selectedSeedUser?.id + "_" + new Date().getTime();
      this.setState({ mediaFileName: mediaFileName });
      setTimeout(this.handleUpload, 500);
    }
  };
  handleUpload = () => {
    const uploadTask = storage
      .child(
        `attachment/community/${this.state.type}/${this.state.mediaFileName}`
      )
      .put(this.state.mediaFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        this.setState({ loading: true });
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .child(
            `attachment/community/${this.state.type}/${this.state.mediaFileName}`
          )
          .getDownloadURL()
          .then((url) => {
            this.setState({ loading: false, url: url });
          });
      }
    );
  };

  render() {
    const tagSuggestionList = this.props.tags?.tagSuggestions;
    return (
      <>
        <IconButton onClick={() => this.handleAttachmentClick("IMAGE")}>
          <InsertPhotoIcon />
        </IconButton>
        <IconButton onClick={() => this.handleAttachmentClick("VIDEO")}>
          <VideoLibraryIcon />
        </IconButton>
        <IconButton onClick={() => this.handleAttachmentClick("AUDIO")}>
          <AudiotrackIcon />
        </IconButton>
        <IconButton onClick={() => this.handleAttachmentClick("FILE")}>
          <AttachFileIcon />
        </IconButton>
        <IconButton onClick={() => this.handleAttachmentClick("MIC")}>
          <MicIcon />
        </IconButton>
        <NewPostImageInput
          isOpen={this.state.openImageUploadModal}
          mediaFile={this.state.mediaFile}
          handleFileInputChange={this.handleFileInputChange}
          caption={this.state.caption}
          handleCaptionChange={this.handleCaptionChange}
          onClose={this.onCloseFileModal}
          postType={this.props.type}
          loading={this.state.loading}
          url={this.state.url}
          mediaFileName={this.state.mediaFileName}
          fileSize={this.state.fileSize}
          repliedPost={
            this.props.type === "thread" ? this.props.repliedPost : null
          }
          tagSuggestionList={tagSuggestionList}
          showTagSuggestions={this.state.showTagSuggestions}
          taggedMembers={this.state.taggedMembers}
          handlePopoverClose={this.handlePopoverClose}
          setTaggedMember={this.setTaggedMember}
          handleKeyPress={this.onKeyDown}
          onCaptionEmojiClick={this.onCaptionEmojiClick}
          showFeedTagSuggestions={this.state.showFeedTagSuggestions}
          filteredList={this.state.filteredList}
        />
        <NewPostVideoInput
          isOpen={this.state.openVideoUploadModal}
          mediaFile={this.state.mediaFile}
          handleFileInputChange={this.handleFileInputChange}
          caption={this.state.caption}
          handleCaptionChange={this.handleCaptionChange}
          onClose={this.onCloseFileModal}
          postType={this.props.type}
          loading={this.state.loading}
          url={this.state.url}
          mediaFileName={this.state.mediaFileName}
          fileSize={this.state.fileSize}
          repliedPost={
            this.props.type === "thread" ? this.props.repliedPost : null
          }
          tagSuggestionList={tagSuggestionList}
          showTagSuggestions={this.state.showTagSuggestions}
          taggedMembers={this.state.taggedMembers}
          handlePopoverClose={this.handlePopoverClose}
          setTaggedMember={this.setTaggedMember}
          handleKeyPress={this.onKeyDown}
          onCaptionEmojiClick={this.onCaptionEmojiClick}
          showFeedTagSuggestions={this.state.showFeedTagSuggestions}
          filteredList={this.state.filteredList}
        />
        <NewPostFileInput
          isOpen={this.state.openFileUploadModal}
          mediaFile={this.state.mediaFile}
          handleFileInputChange={this.handleFileInputChange}
          caption={this.state.caption}
          handleCaptionChange={this.handleCaptionChange}
          onClose={this.onCloseFileModal}
          postType={this.props.type}
          loading={this.state.loading}
          url={this.state.url}
          mediaFileName={this.state.mediaFileName}
          fileName={this.state.fileName}
          fileSize={this.state.fileSize}
          repliedPost={
            this.props.type === "thread" ? this.props.repliedPost : null
          }
          tagSuggestionList={tagSuggestionList}
          showTagSuggestions={this.state.showTagSuggestions}
          taggedMembers={this.state.taggedMembers}
          handlePopoverClose={this.handlePopoverClose}
          setTaggedMember={this.setTaggedMember}
          handleKeyPress={this.onKeyDown}
          onCaptionEmojiClick={this.onCaptionEmojiClick}
          showFeedTagSuggestions={this.state.showFeedTagSuggestions}
          filteredList={this.state.filteredList}
        />
        <NewPostAudioInput
          isOpen={this.state.openAudioUploadModal}
          mediaFile={this.state.mediaFile}
          handleFileInputChange={this.handleFileInputChange}
          caption={this.state.caption}
          handleCaptionChange={this.handleCaptionChange}
          onClose={this.onCloseFileModal}
          postType={this.props.type}
          loading={this.state.loading}
          url={this.state.url}
          mediaFileName={this.state.mediaFileName}
          fileSize={this.state.fileSize}
          repliedPost={
            this.props.type === "thread" ? this.props.repliedPost : null
          }
          tagSuggestionList={tagSuggestionList}
          showTagSuggestions={this.state.showTagSuggestions}
          taggedMembers={this.state.taggedMembers}
          handlePopoverClose={this.handlePopoverClose}
          setTaggedMember={this.setTaggedMember}
          handleKeyPress={this.onKeyDown}
          onCaptionEmojiClick={this.onCaptionEmojiClick}
          showFeedTagSuggestions={this.state.showFeedTagSuggestions}
          filteredList={this.state.filteredList}
        />
        <NewPostMicInput
          isOpen={this.state.openMicUploadModal}
          mediaFile={this.state.mediaFile}
          handleRecordInput={this.handleRecordInput}
          caption={this.state.caption}
          handleCaptionChange={this.handleCaptionChange}
          onClose={this.onCloseFileModal}
          postType={this.props.type}
          loading={this.state.loading}
          url={this.state.url}
          mediaFileName={this.state.mediaFileName}
          fileSize={this.state.fileSize}
          repliedPost={
            this.props.type === "thread" ? this.props.repliedPost : null
          }
          tagSuggestionList={tagSuggestionList}
          showTagSuggestions={this.state.showTagSuggestions}
          taggedMembers={this.state.taggedMembers}
          handlePopoverClose={this.handlePopoverClose}
          setTaggedMember={this.setTaggedMember}
          handleKeyPress={this.onKeyDown}
          onCaptionEmojiClick={this.onCaptionEmojiClick}
          showFeedTagSuggestions={this.state.showFeedTagSuggestions}
          filteredList={this.state.filteredList}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedSeedUser: state.seedUsers?.selectedSeedUser,
  feedList: state.feedList,
  tags: state.tags,
});
export default connect(mapStateToProps, {
  fetchTagSuggestions,
  closeTagSuggestions,
  fetchTagSuggestionsNew
})(MediaUploads);
