import React from "react";

import {
  StarterBadge,
  SilverBadge,
  GoldBadge,
  DiamondBadge,
  InfluencerBadge,
} from "../assets/svg";

//auth
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FETCHED_USER_DETAILS = "FETCHED_USER_DETAILS";
export const FETCH_USER_PROFILE_IMAGE = "FETCH_USER_PROFILE_IMAGE";
export const SET_GROUP_PANEL_OPEN = "SET_GROUP_PANEL_OPEN";
export const SET_GROUP_PANEL_CLOSE = "SET_GROUP_PANEL_CLOSE";

//groups list
export const GROUP_LOADING_STARTED = "GROUP_LOADING_STARTED";
export const UPDATE_GROUPS_LIST = "UPDATE_GROUPS_LIST";
export const INIT_GROUP_LAST_SEEN_AT = "INIT_GROUP_LAST_SEEN_AT";
export const UPDATE_GROUP_LAST_SEEN_AT = "UPDATE_GROUP_LAST_SEEN_AT";
export const FETCHED_GROUP_NOTIFICATION = "FETCHED_GROUP_NOTIFICATION";
export const RESET_UNREAD_COUNT = "RESET_UNREAD_COUNT";

//feed
export const FEED_LOADING_STARTED = "FEED_LOADING_STARTED";
export const UPDATED_FEED = "UPDATED_FEED";
export const UPDATED_FEED_ON_LOAD_MORE = "UPDATED_FEED_ON_LOAD_MORE";
export const SET_SELECTED_FEED = "SET_SELECTED_FEED";

//tag suggestions
export const FETCH_TAG_SUGGESTIONS = "FETCH_TAG_SUGGESTIONS";
export const CLOSE_TAG_SUGGESTIONS = "CLOSE_TAG_SUGGESTIONS";
export const FETCH_FEED_TAG_SUGGESTIONS = "FETCH_FEED_TAG_SUGGESTIONS";
export const FETCH_THREAD_TAG_SUGGESTIONS = "FETCH_THREAD_TAG_SUGGESTIONS";
export const CLOSE_FEED_TAG_SUGGESTIONS = "CLOSE_FEED_TAG_SUGGESTIONS";
export const CLOSE_THREAD_TAG_SUGGESTIONS = "CLOSE_THREAD_TAG_SUGGESTIONS";

//post
export const CLAPPED_POST = "CLAPPED_POST";
export const POST_MESSAGE_SUCCESS = "POST_MESSAGE_SUCCESS";
export const POST_INLINE_MESSAGE_SUCCESS = "POST_INLINE_MESSAGE_SUCCESS";
export const POST_MESSAGE_ERROR = "POST_MESSAGE_ERROR";
export const OPEN_CLAPPER_LIST = "OPEN_CLAPPER_LIST";
export const CLOSE_CLAPPER_LIST = "CLOSE_CLAPPER_LIST";
export const UPDATE_CLAPPERS_LIST = "UPDATE_CLAPPERS_LIST";

//seed members
export const RESET_SEED_MEMBERS = "RESET_SEED_MEMBERS";
export const FETCHED_SEED_MEMBERS = "FETCHED_SEED_MEMBERS";
export const SELECTED_SEED_MEMBER = "SELECTED_SEED_MEMBER";
export const UPDATE_SEED_PROFILE_URL = "UPDATE_SEED_PROFILE_URL";

//thread
export const OPEN_THREAD_PANEL = "OPEN_THREAD_PANEL";
export const CLOSE_THREAD_PANEL = "CLOSE_THREAD_PANEL";
export const FETCHED_REPLIES = "FETCHED_REPLIES";
export const THREAD_REPLY_UPDATE = "THREAD_REPLY_UPDATE";
export const THREAD_REPLY_MESSAGE_SUCCESS = "THREAD_REPLY_MESSAGE_SUCCESS";

//user
export const OPEN_USER_DETAILS = "OPEN_USER_DETAILS";
export const CLOSE_USER_DETAILS = "CLOSE_USER_DETAILS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";

//post reply count
export const INIT_REPLY_COUNT = "INIT_REPLY_COUNT";
export const UPDATE_POST_REPLY_COUNT = "UPDATE_POST_REPLY_COUNT";
export const RESET_REPLY_COUNT = "RESET_REPLY_COUNT";

//post clap count
export const INIT_CLAP_COUNT = "INIT_CLAP_COUNT";
export const UPDATE_POST_CLAP_COUNT = "UPDATE_POST_CLAP_COUNT";
export const RESET_CLAP_COUNT = "RESET_CLAP_COUNT";

//notifications
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const SELECTED_NOTIFICATION = "SELECTED_NOTIFICATION";
export const NOTIFICATION_COUNT_DECREASE = "NOTIFICATION_COUNT_DECREASE";

//badges
export const CLAP_ICONS = {
  starter: <StarterBadge />,
  silver: <SilverBadge />,
  gold: <GoldBadge />,
  diamond: <DiamondBadge />,
  influencer: <InfluencerBadge />,
};

//clap levels
export const CLAP_LEVELS = [
  { level: "influencer", value: 15000 },
  { level: "diamond", value: 10000 },
  { level: "gold", value: 1000 },
  { level: "silver", value: 100 },
  { level: "starter", value: 0 },
];

//clap colors
export const CLAP_COLORS = {
  starter: "#fff",
  silver: "#6A6A6A",
  gold: "#fc9f5b",
  diamond: "#2F68E3",
  influencer: "#FE5196",
};

export const REPORT_OPTIONS = [
  {
    title: "Fake Job/MLM",
    description:
      "Charging money or asking for private information in the promise of Job/Business",
    value: "mlm",
  },
  {
    title: "Sexual Harassment",
    description:
      "Nudity, unwanted romantic advances, sexual remarks, sex trafficking.",
    value: "sexual_harassment",
  },
  {
    title: "Hate Speech",
    description:
      " Abuse or threat against a particular group, especially on the basis of race, religion, or sexual orientation.",
    value: "hate_speech",
  },
  {
    title: "Suspicious Post",
    description:
      "Sharing irrelevant or repeated content for promotion or monetary gain.",
    value: "suspicious_post",
  },
];
