import React, { Component } from "react";
import { connect } from "react-redux";
import "./Feed.css";
import FeedHeader from "./FeedHeader";
import FeedList from "./FeedList";
import FeedResponse from "../feedResponse/FeedResponse";
import Thread from "../thread/Thread";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import ClappersList from "../clappers/ClappersList";
import UserInfo from "../userInfo/UserInfo";
import SuccessToast from "../dashboard/SuccessToast";
import {
  setToastOpen,
  setThreadClose,
} from "../../actions/create-new-post.action";
import { isMobile } from "../../utils/formatDate";

const threadDrawerWidth = 400;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  threadDrawer: {
    [theme.breakpoints.up("sm")]: {
      width: threadDrawerWidth,
      flexShrink: 0,
    },
  },
  threadContentShift: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${threadDrawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: threadDrawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  threadDrawerPaper: {
    width: threadDrawerWidth,
  },
  threadDrawerPaperMobile: {
    width: "100%",
  },
  content: {
    flexGrow: 1,
  },
});

class Feed extends Component {
  handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.setToastOpen(false);
  };
  render() {
    const { classes, feedList } = this.props;
    const open = this.props.thread?.threadOpen;
    const toastOpen = feedList?.isPostSuccess;
    return (
      <div
        className={`feed ${classNames(classes.content, {
          [classes.threadContentShift]: open,
        })}`}
      >
        <FeedHeader />
        <FeedList />
        {feedList?.selectedFeed && (
          <FeedResponse type="post" selectedFeed={feedList?.selectedFeed} />
        )}
        {isMobile() && (
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="right"
              open={open}
              onClose={this.props.setThreadClose}
              classes={{
                paper: classes.threadDrawerPaperMobile,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <Thread />
            </Drawer>
          </Hidden>
        )}
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.threadDrawer}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
              paper: classes.threadDrawerPaper,
            }}
          >
            <Thread />
          </Drawer>
        </Hidden>
        <ClappersList />
        <UserInfo />
        <SuccessToast
          open={toastOpen}
          handleClose={this.handleToastClose}
          isPost={true}
        />
      </div>
    );
  }
}
const FeedContainer = withStyles(styles, { withTheme: true })(Feed);

const mapStateToProps = (state) => ({
  feedList: state.feedList,
  thread: state.thread,
});
export default connect(mapStateToProps, { setToastOpen, setThreadClose })(
  FeedContainer
);
