import {
  INIT_REPLY_COUNT,
  UPDATE_POST_REPLY_COUNT,
  RESET_REPLY_COUNT,
} from "../constants/types";

export const initReplyCount = (feedReplyCount) => (dispatch) => {
  dispatch({
    type: INIT_REPLY_COUNT,
    payload: feedReplyCount,
  });
};

export const updateReplyCount = (postId) => (dispatch) => {
  dispatch({
    type: UPDATE_POST_REPLY_COUNT,
    payload: postId,
  });
};

export const resetReplyCount = () => (dispatch) => {
  dispatch({
    type: RESET_REPLY_COUNT,
    payload: true,
  });
};
