import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { setSelectedSeedUser } from "../../actions/auth.action";
import { fetchUserDetails } from "../../actions/user-details.action";
import useFirebaseFileLoader from "../../utils/FirebaseFileLoader";
import "./SeedUser.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fff",
      light: "fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  label: {
    fontSize: 13,
  },
  whiteColor: {
    color: "white",
  },
}));
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 350,
      width: 220,
    },
  },
};

function getLabelStyles(theme) {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14,
  };
}

export default function SeedUser() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { selectedSeedUser, users } = useSelector((state) => state.seedUsers);
  const feedList = useSelector((state) => state.feedList);
  const selectedFeed = feedList?.selectedFeed;
  const [user, setUser] = useState(selectedSeedUser?.id);
  const seedProfilePath = selectedSeedUser?.photo_firebase_path;
  const [seedProfileUrl, loading] = useFirebaseFileLoader(seedProfilePath);
  const [currentUser, setCurrentUser] = useState(selectedSeedUser);
  const handleChange = (event) => {
    setUser(event.target.value);
    let newValue = users && users.find((u) => u.id === event.target.value);
    setCurrentUser(newValue);
    dispatch(setSelectedSeedUser(newValue));
  };
  const fetchUserInformation = () => {
    let user = currentUser?.id;
    dispatch(fetchUserDetails(user));
  };
  return (
    <MuiThemeProvider theme={theme}>
      {selectedFeed && (
        <div className="feed__SelectSeed">
          <Avatar src={seedProfileUrl} onClick={() => fetchUserInformation()} />
          <FormControl className={classes.formControl}>
            <Select
              style={getLabelStyles(theme)}
              id="seed-user"
              displayEmpty
              value={selectedSeedUser?.id}
              disableUnderline
              onChange={handleChange}
              MenuProps={MenuProps}
              classes={{
                root: classes.whiteColor,
                icon: classes.whiteColor,
              }}
            >
              {users &&
                users.map((user) => (
                  <MenuItem
                    key={user?.id}
                    value={user?.id}
                    className={classes.label}
                  >
                    {user?.full_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      )}
    </MuiThemeProvider>
  );
}
