import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import { Provider } from "react-redux";
import Login from "./components/auth/Login";
import PageNotFound from "./components/dashboard/PageNotFound";
import MainLayout from "./components/dashboard/MainLayout";

import store from "./store";
import ProtectedRoute from "./components/routing/ProtectedRoute";

class App extends Component {
  componentDidMount() {
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="App">
            <Switch>
              <Route exact path="/" component={Login} />
              <ProtectedRoute exact path="/feed" component={MainLayout} />
              <Route component={PageNotFound} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
