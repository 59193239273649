import React from "react";
import styled from "styled-components";
import ErrorIcon from "@material-ui/icons/Error";
const UnverifiedJobPost = () => {
  return (
    <MainContainer>
      <IconContainer>
        <ErrorIcon color="error" />
      </IconContainer>
      <TextContainer>
        <TextHeading>Yeh job verified nahi hai. Dhyan rahe!</TextHeading>
        <TextSubHeading>
          Fake jobs aur scams se dur rehne ke liye humare guidelines padhe.
        </TextSubHeading>
      </TextContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  background-color: #f859631f;
  margin: 10px 20px;
  padding: 10px 25px;
  border-radius: 4px;
`;

const IconContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9;
  gap: 5px;
`;
const TextHeading = styled.div`
  font-weight: 500;
  color: #f85963;
`;
const TextSubHeading = styled.div`
  font-weight: 400;
  color: #7a7a7a;
  font-size: 11px;
`;
export default UnverifiedJobPost;
