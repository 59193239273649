import React from "react";
import { useDispatch } from "react-redux";
import { Avatar } from "@material-ui/core";
import useFirebaseFileLoader from "../../utils/FirebaseFileLoader";
import { timeAgo } from "../../utils/formatDate";
import { openNotification } from "../../actions/notification-action";
import Mixpanel from "../../utils/Mixpanel";
import "./Notifications.css";

export default function Notifications({ notif, onClose }) {
  const notifInfo = notif?.data;
  const dispatch = useDispatch();

  const [profileUrl, loading] = useFirebaseFileLoader(notif?.icon_url);

  const handleNotifClick = () => {
    onClose();
    dispatch(openNotification(notif));
    Mixpanel.track("CHAT_GROUP_REFRESH", {
      GROUP_ID: notifInfo?.group_id,
      SENDER: "web",
    });
  };
  return (
    <div className="notification" onClick={handleNotifClick}>
      <Avatar src={profileUrl} />
      <div className="notification__content">
        <p className="notification__title">{notif?.title}</p>
        <div className="notification__info">
          {notifInfo?.group_name && (
            <p className="notification__group">{notifInfo?.group_name}</p>
          )}
          <p className="notification__time">{timeAgo(notif?.last_updated)}</p>
        </div>
      </div>
      {notif?.read_status === "unread" && (
        <div className="notification__btn">
          <span>NEW</span>
        </div>
      )}
    </div>
  );
}
