import { CLAP_LEVELS, CLAP_ICONS, CLAP_COLORS } from "../constants/types";

export const isMobile = () => {
  const isMobileView = navigator.userAgent
    .toLowerCase()
    .match(/android|blackBerry|iPhone|iPad|iPod|mini|iemobile|wpdesktop/i);
  return Boolean(isMobileView);
};

export const dateFormat = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let todayDateFunction = new Date();
  let newDateFunction = new Date(date);
  let todayDate = todayDateFunction.getDate();
  let currentMonth = todayDateFunction.getMonth();
  let currentYear = todayDateFunction.getFullYear();
  let newDate = newDateFunction.getDate();
  let newMonth = newDateFunction.getMonth();
  let newYear = newDateFunction.getFullYear();
  let hours = newDateFunction.getHours();
  let minutes = newDateFunction.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  if (
    todayDate === newDate &&
    currentMonth === newMonth &&
    currentYear === newYear
  ) {
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  } else if (newDate === todayDate - 1) {
    let strTime = `yesterday, ${hours}:${minutes} ${ampm}`;
    return strTime;
  } else {
    let strTime = `${newDate} ${monthNames[newMonth]} ${hours}:${minutes} ${ampm}`;
    return strTime;
  }
};

export const sortBy = (prop) => {
  return function(a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
};

export const abbreviateNumber = function(num, fixed) {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return "0";
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ["", "K", "M", "B", "T"][k]; // append power
  return e;
};

const logBase = (() => {
  const { log } = Math;
  return (n, base) => {
    return log(n) / (base ? log(base) : 1);
  };
})();
export const convertToMBs = (byte) => {
  if (byte === 0) {
    return "0 B";
  }
  const sizeName = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(logBase(byte, 1024).toFixed(1));
  const p = 1024 ** i;
  const s = (byte / p).toFixed(1);
  const size = `${s} ${sizeName[i]}`;
  return size;
};

export const generateClapsBadge = (clapCount) => {
  if (clapCount) {
    const clap = CLAP_LEVELS.find(({ value }) => clapCount >= value);
    return CLAP_ICONS[clap?.level];
  }
  return null;
};

export const generateClapsColors = (clapCount) => {
  const clap = CLAP_LEVELS.find(({ value }) => clapCount >= value);
  return CLAP_COLORS[clap?.level];
};
export const generateClapsLevel = (clapCount) => {
  const clap = CLAP_LEVELS.find(({ value }) => clapCount >= value);
  return clap?.level;
};

export const filterDuplicates = (arr) => {
  let result = arr.reduce((unique, o) => {
    if (!unique.some((obj) => obj.id === o.id)) {
      unique.push(o);
    }
    return unique;
  }, []);
  return result;
};

export const timeAgo = (timeCreated) => {
  let date = new Date(timeCreated); // some mock date
  let milliseconds = date.getTime();
  let seconds = Math.floor((new Date() - milliseconds) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    let time = Math.floor(interval);
    return time + (time > 1 ? " years ago" : " year ago");
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    let time = Math.floor(interval);
    return time + (time > 1 ? " months ago" : " month ago");
  }
  interval = seconds / 86400;
  if (interval > 1) {
    let time = Math.floor(interval);
    return time + (time > 1 ? " days ago" : " day ago");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    let time = Math.floor(interval);
    return time + (time > 1 ? " hours ago" : " hour ago");
  }
  interval = seconds / 60;
  if (interval > 1) {
    let time = Math.floor(interval);
    return time + (time > 1 ? " minutes ago" : " minute ago");
  }
  return Math.floor(seconds) + "now";
};
