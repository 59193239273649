import {
  LOGIN_SUCCESS,
  FETCHED_USER_DETAILS,
  FETCH_USER_PROFILE_IMAGE,
  LOGOUT,
  SET_GROUP_PANEL_OPEN,
  SET_GROUP_PANEL_CLOSE,
} from "../constants/types";
import { isMobile } from "../utils/formatDate";

const initialState = {
  token: localStorage.getItem("userToken"),
  isAuthenticated:
    localStorage.getItem("userToken") !== null
      ? localStorage.getItem("isAuthenticated")
      : false,
  loading: true,
  user: JSON.parse(localStorage.getItem("user")),
  groupMobileOpen: isMobile() ? true : false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        token: payload.key,
        isAuthenticated: true,
        loading: false,
      };

    case FETCHED_USER_DETAILS:
      return {
        ...state,
        user: payload,
      };
    case FETCH_USER_PROFILE_IMAGE:
      return {
        ...state,
        user: { ...state.user, profileUrl: payload },
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    case SET_GROUP_PANEL_OPEN:
      return {
        ...state,
        groupMobileOpen: true,
      };
    case SET_GROUP_PANEL_CLOSE:
      return {
        ...state,
        groupMobileOpen: false,
      };
    default:
      return state;
  }
}
