import {
  CLOSE_THREAD_PANEL,
  OPEN_THREAD_PANEL,
  FETCHED_REPLIES,
  CLAPPED_POST,
  POST_MESSAGE_SUCCESS,
  POST_INLINE_MESSAGE_SUCCESS,
  POST_MESSAGE_ERROR,
  OPEN_CLAPPER_LIST,
  CLOSE_CLAPPER_LIST,
  UPDATE_CLAPPERS_LIST,
  THREAD_REPLY_UPDATE,
  THREAD_REPLY_MESSAGE_SUCCESS,
} from "../constants/types";
import { Get, Post } from "../utils/api";
import APIConfig from "../utils/APIConfig";
import { fetchFeed } from "./update-feed-action";
import { updateReplyCount } from "./feed-reply-count.action";
import {
  fetchThreadTagSuggestions,
  closeThreadTagSuggestions,
} from "./update-tag-suggestion.action";
import { closeNotification } from "./notification-action";

export const setThreadOpen = (post) => (dispatch) => {
  dispatch({
    type: OPEN_THREAD_PANEL,
    payload: post,
  });
  dispatch(closeThreadTagSuggestions());
  dispatch(fetchRepliesOnPost(post?.id));
};

export const setThreadClose = () => (dispatch) => {
  dispatch({
    type: CLOSE_THREAD_PANEL,
    payload: true,
  });
  dispatch(closeNotification());
};

export const updateThreadReply = (lastReply) => (dispatch) => {
  dispatch({
    type: THREAD_REPLY_UPDATE,
    payload: lastReply,
  });
};

export const postMessage = (post, groupId, type) => (dispatch) => {
  Post(APIConfig.BASE_PATH + `v5/groups/${groupId}/posts?is_ops_activity=true`, post)
    .then((res) => {
      if (res?.id) {
        if (type === "post") {
          dispatch(fetchFeed(groupId, true, true, res));
        }
        if (type === "reply") {
          dispatch({
            type: POST_INLINE_MESSAGE_SUCCESS,
            payload: post?.replied_to,
          });
          setTimeout(() => {
            dispatch({
              type: POST_INLINE_MESSAGE_SUCCESS,
              payload: null,
            });
          }, 6000);
          // if (groupId === post?.group) {
          //   dispatch(fetchFeed(groupId, false));
          //   dispatch(updateReplyCount(post?.replied_to));
          // }
        }
        if (type === "thread") {
          dispatch(updateThreadReply(res));
          dispatch({
            type: THREAD_REPLY_MESSAGE_SUCCESS,
            payload: null,
          });
          // if (groupId === post?.group) {
          //   dispatch(fetchFeed(groupId, false));
          // }
        }
        if (type === "thread" || type === "post") {
          dispatch(setToastOpen(true));
        }
      }
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: POST_MESSAGE_ERROR,
        payload: post.replied_to,
      });
      setTimeout(() => {
        dispatch({
          type: POST_MESSAGE_ERROR,
          payload: null,
        });
      }, 4000);
    });
};

export const setToastOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: POST_MESSAGE_SUCCESS,
    payload: isOpen,
  });
};

export const fetchRepliesOnPost = (post_id) => (dispatch, getState) => {
  let { thread } = getState();
  Get(
    `${APIConfig.BASE_PATH}v1/post/${post_id}/reply/?page_size=10&page=${thread.page}`
  )
    .then((res) => {
      let replyList = res?.results?.reverse();
      let previousCount = res?.count > 10 ? res?.count - thread.page * 10 : 0;
      dispatch({
        type: FETCHED_REPLIES,
        payload: replyList,
        page: thread.page + 1,
        prevCount: previousCount,
      });
      dispatch(fetchThreadTagSuggestions(replyList));
    })
    .catch((error) => console.error(error));
};

export const clapPost = (postId, data) => (dispatch) => {
  Post(APIConfig.BASE_PATH + `v1/claps/${postId}?is_ops_activity=true`, data)
    .then((res) => {
      if (res?.post_id === postId) {
        dispatch({
          type: CLAPPED_POST,
          payload: res?.total_clap_count,
        });
      }
    })
    .catch((error) => console.error(error));
};

export const fetchClappersList = (postId) => (dispatch) => {
  dispatch(openClappersList());
  Get(APIConfig.BASE_PATH + `v1/claps/${postId}/users`)
    .then((res) => {
      dispatch({
        type: UPDATE_CLAPPERS_LIST,
        payload: res,
      });
    })
    .catch((error) => console.error(error));
};

export const openClappersList = () => (dispatch) => {
  dispatch({
    type: OPEN_CLAPPER_LIST,
    payload: true,
  });
};

export const closeClappersList = () => (dispatch) => {
  dispatch({
    type: CLOSE_CLAPPER_LIST,
    payload: true,
  });
};
