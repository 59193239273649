import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, IconButton, Menu, MenuItem } from "@material-ui/core";
import { storage } from "../../firebase";
import { Button } from "@material-ui/core";
import { ReactComponent as ClapIcon } from "../../assets/PostClap.svg";
import { ReactComponent as ReportIcon } from "../../assets/Report.svg";
import { ReactComponent as FileIcon } from "../../assets/File.svg";
import { ReactComponent as ClappedIcon } from "../../assets/Clapped.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { dateFormat, convertToMBs } from "../../utils/formatDate";
import ReactAudioPlayer from "react-audio-player";
import MicrolinkCard from "@microlink/react";
import Linkify from "react-linkify";
import ReactHtmlParser from "react-html-parser";
import {
  setThreadOpen,
  clapPost,
  fetchClappersList,
} from "../../actions/create-new-post.action";
import PostReply from "../postReply/PostReply";
import { Get, Post } from "../../utils/api";
import APIConfig from "../../utils/APIConfig";
import ReportOptions from "../reportOptions/ReportOptions";
import ReportMessage from "../reportMessage/ReportMessage";
import LastReply from "../thread/ReplyComponent";
import SuccessToast from "../dashboard/SuccessToast";
import Badge from "../badge/Badge";
import MediaModal from "../mediaModal/MediaModal";
import { fetchUserDetails } from "../../actions/user-details.action";
import { updateClapCount } from "../../actions/feed-clap-count.action";
import Mixpanel from "../../utils/Mixpanel";
import { GetCurrentStorage } from "../../utils/LocalStorage";
import "./Post.css";
import UnverifiedJobPost from "./UnverifiedJobPost";

const loggedInUser = GetCurrentStorage("user");

class PostComponent extends Component {
  state = {
    avatarImage: "",
    postUrl: "",
    lastReplyImage: "",
    showToolIcons: false,
    playVideo: true,
    replyText: "",
    clapDetail: {},
    fetchedClappedDetail: {},
    openReportOptions: false,
    openReportMessage: false,
    openReportFake: false,
    openReportPost: false,
    openReportPostContent: false,
    reportType: "",
    showPostInfo: false,
    headerAnchorEl: null,
    anchorEl: null,
    contentType: "",
    reportOption: {},
    messageBody: "",
    showToast: false,
    openMedia: false,
    is_unverified_job: false,
  };
  componentDidMount() {
    const { postData } = this.props;
    const photoUrlPath = postData?.user?.photo_firebase_path;
    const postUrlPath = postData?.data?.url;
    const lastReplyUrlPath =
      postData?.last_replied_post?.user?.photo_firebase_path;
    this.urlGenerate(photoUrlPath, "photoUrl");
    this.urlGenerate(postUrlPath, "postUrl");
    this.urlGenerate(lastReplyUrlPath, "lastReplyUrl");
  }
  handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ showToast: false });
  };
  urlGenerate = (urlPath, type) => {
    if (urlPath) {
      storage
        .child(urlPath)
        .getDownloadURL()
        .then((url) => {
          this.setState({ [type]: url });
        })
        .catch((error) => {});
    }
  };
  handleThread = () => {
    let { postData, thread, postIndex } = this.props;
    if (thread?.post?.id !== postData?.id) {
      this.props.setThreadOpen({
        ...postData,
        avatarImage: this.state.photoUrl,
        postImage: this.state.postUrl,
        lastReplyImage: this.state.lastReplyUrl,
        postIndex: postIndex,
      });
    }
  };
  showToolBox = () => {
    this.setState({ showToolIcons: true });
  };
  onBlur = () => {
    this.setState({ showToolIcons: false });
  };
  onFocus = () => {
    this.setState({ showToolIcons: true });
  };
  renderMessage = (taggedUsers = [], message) => {
    let newMessage = message;
    let shiftBy = 0;
    if (taggedUsers.length > 0) {
      taggedUsers.forEach((membersDetails) => {
        let taggedUser =
          message &&
          message.substring(membersDetails.start, membersDetails.end + 1);
        newMessage =
          newMessage.substring(0, membersDetails.start + shiftBy) +
          '<span class="taggedUser">' +
          taggedUser +
          "</span>" +
          newMessage.substring(membersDetails.end + 1 + shiftBy);
        shiftBy = shiftBy + '<span class="taggedUser"></span>'.length;
      });
      return newMessage;
    } else {
      return message;
    }
  };

  findGroupName = (id) => {
    let group;
    let groupList = this.props.groups?.groupList;
    if (groupList && groupList.length > 0) {
      group = groupList.find((group) => group.id === id);
      return group;
    }
    return null;
  };
  onClapPost = () => {
    const postId = this.props.postData?.id;
    const userId = this.props.seedUsers?.selectedSeedUser?.id;
    const selectedGroup = this.findGroupName(this.props.postData?.group);
    let data = {
      count: 1,
      user_id: userId,
    };
    Post(APIConfig.BASE_PATH + `v1/claps/${postId}?is_ops_activity=true`, data)
      .then((res) => {
        if (res?.post_id === postId) {
          this.setState({ clapDetail: res });
          Get(APIConfig.BASE_PATH + `v1/claps/${postId}/users`)
            .then((response) => {
              this.setState({ fetchedClappedDetail: response });
              this.props.updateClapCount(
                this.props.postData?.id,
                response?.clap_count
              );
              Mixpanel.track("CHAT_CLAP_CLICK", {
                GROUP_ID: this.props.postData?.group,
                GROUP_NAME: selectedGroup?.name,
                SENDER: "web",
                POST_ID: postId,
                POSITION: this.props.postIndex,
              });
            })
            .catch((error) => console.error(error));
        }
      })
      .catch((error) => console.error(error));
  };
  handleHeaderReportClick = (e) => {
    this.setState({ headerAnchorEl: e.currentTarget });
  };
  handleHeaderReportClose = () => {
    this.setState({ headerAnchorEl: null });
  };
  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  openReportFakeModal = () => {
    this.setState({ openReportFake: true });
  };
  closeReportFakeModal = () => {
    this.setState({ openReportFake: false });
  };
  openReportOptionsModal = () => {
    this.setState({ openReportOptions: true });
    this.setMessageBody("");
    this.setReportOption({});
  };
  handleReportUserClick = () => {
    this.openReportOptionsModal();
    this.setState({ reportType: "user" });
    const reportedUserId = this.props.postData?.user?.id;
    Mixpanel.track("REPORT_USER_CLICKED", {
      SOURCE: "1:1, Profile",
      REPORTED_USER_ID: reportedUserId,
      USER_ID: loggedInUser?.id,
      SENDER: "web",
    });
  };
  handleReportPostClick = () => {
    this.openReportOptionsModal();
    this.setState({ reportType: "post" });

    const { postData } = this.props;
    const postId = postData?.id;
    const reportedUserId = postData?.user?.id;
    const groupId = postData?.group;
    Mixpanel.track("REPORT_POST_CLICKED", {
      SOURCE: "1:1, Profile",
      REPORTED_USER_ID: reportedUserId,
      USER_ID: loggedInUser?.id,
      POST_ID: postId,
      GROUP_ID: groupId,
      TYPE: "OM",
      SENDER: "web",
    });
  };
  closeReportOptionsModal = () => {
    this.handleHeaderReportClose();
    this.setState({ openReportOptions: false, reportType: "" });
  };
  openReportMessageModal = () => {
    this.setState({
      openReportOptions: false,
      openReportMessage: true,
    });
  };
  closeReportMessageModal = () => {
    this.handleHeaderReportClose();
    this.setState({
      openReportOptions: false,
      openReportMessage: false,
      reportType: "",
    });
    this.setMessageBody("");
    this.setReportOption({});
  };

  setMessageBody = (message) => {
    this.setState({ messageBody: message });
  };
  handleBackToOptionsModal = () => {
    this.setMessageBody("");
    this.setState({
      openReportMessage: false,
      openReportOptions: true,
    });
  };
  handleReportPostSubmitClick = () => {
    const { reportOption, messageBody } = this.state;
    const { postData } = this.props;
    let data = {
      report_type: reportOption?.value,
      message_info: {
        body: messageBody || "",
      },
    };
    const postId = postData?.id;
    const userId = postData?.user?.id || "";
    Post(APIConfig.BASE_PATH + `v1/post/report/${postId}`, data)
      .then((res) => {
        if (res) {
          Mixpanel.track("REPORT_POST_SUBMITTED", {
            SOURCE: "1:1, Profile",
            AUTHOR_ID: userId,
            OPTION: reportOption?.title,
            FEEDBACK_TEXT: messageBody,
            USER_ID: loggedInUser?.id,
            TYPE: "OM",
            SENDER: "web",
          });
          setTimeout(this.closeReportMessageModal, 1000);
          this.setState({ showToast: true });
        }
      })
      .catch((error) => {
        this.closeReportMessageModal();
        console.error(error);
      });
  };
  handleReportUserSubmitClick = () => {
    const { reportOption, messageBody } = this.state;
    const { postData } = this.props;
    let data = {
      report_type: reportOption?.value,
      message_info: {
        body: messageBody || "",
      },
    };
    const userId = postData?.user?.id || "";
    Post(APIConfig.REPORT_USER + `${userId}/report/`, data)
      .then((res) => {
        if (res) {
          Mixpanel.track("REPORT_USER_SUBMITTED", {
            SOURCE: "1:1, Profile",
            REPORTED_USER_ID: userId,
            OPTION: reportOption?.title,
            FEEDBACK_TEXT: messageBody,
            USER_ID: loggedInUser?.id,
            SENDER: "web",
          });
          setTimeout(this.closeReportMessageModal, 1000);
          this.setState({ showToast: true });
        }
      })
      .catch((error) => {
        this.closeReportMessageModal();
        console.error(error);
      });
  };
  openReportPostModal = () => {
    this.handleClose();
    this.setState({ openReportPost: true });
  };
  closeReportPostModal = () => {
    this.setState({ openReportPost: false });
  };
  setContentType = (value) => {
    this.setState({ contentType: value });
  };
  setReportOption = (value) => {
    this.setState({ reportOption: value });
  };
  handleReportPost = () => {
    let data = {
      report_type: this.state.contentType,
      message_info: {
        body: "",
      },
    };
    const postId = this.props.postData?.id;
    Post(APIConfig.BASE_PATH + `v1/post/report/${postId}`, data)
      .then((res) => {
        setTimeout(() => {
          this.closeReportPostContentModal();
          this.closeReportPostModal();
          this.setState({ contentType: "", showToast: true });
        }, 1000);
      })
      .catch((error) => {
        this.closeReportPostContentModal();
        this.closeReportPostModal();
        console.error(error);
        this.setState({ contentType: "" });
      });
  };
  openReportPostContentModal = () => {
    this.handleClose();
    this.setState({ openReportPostContent: true });
  };
  closeReportPostContentModal = () => {
    this.setState({
      openReportContent: false,
      openReportPostContent: false,
    });
  };
  togglePostMeta = () => {
    this.setState({ showPostInfo: !this.state.showPostInfo });
  };
  openClapsList = () => {
    this.props.fetchClappersList(this.props.postData?.id);
    const selectedGroup = this.findGroupName(this.props.postData?.group);
    Mixpanel.track("CHAT_CLAPPERS_CLICK", {
      GROUP_ID: this.props.postData?.group,
      GROUP_NAME: selectedGroup?.name,
      SENDER: "web",
      POSITION: this.props.postIndex,
    });
  };

  openMediaModal = () => {
    this.setState({
      openMedia: true,
    });
  };
  handleMediaClose = () => {
    this.setState({
      openMedia: false,
    });
  };

  markJobPostAsUnverfied = () => {
    const { postData } = this.props;
    const postId = postData?.id;
    const data = {
      action_taken: "mark_unverified_job",
      violation_type: "mlm",
      audit_type: "manual_flag",
      tns_remarks: "Marked manually from Community Ops",
      tns_agent_email: loggedInUser?.full_name,
      post_id: postId,
    };

    Post(APIConfig.BASE_PATH + `v1/tns-action-manual-flag/`, data)
      .then((res) => {
        this.setState({
          is_unverified_job: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleUnverifiedPostClick = () => {
    this.markJobPostAsUnverfied();
  };

  handleUnverifiedAndReportPostClick = () => {
    this.markJobPostAsUnverfied();
    this.setState({ contentType: "mlm" }, () => {
      this.handleReportPost();
    });
  };

  render() {
    const {
      postData,
      feedList,
      clapCountList,
      replyCountList,
      seedUsers,
    } = this.props;
    const user = postData?.user;
    const postDetail = postData?.data;
    const workDetail = user?.work_info;
    const lastReply = postData?.last_replied_post;
    const taggedUsers = postDetail?.taggedMembersList;
    let message = postDetail?.caption || postDetail?.text || "";
    const urlMatches =
      (message && message.match(/\b(http|https)?:\/\/\S+/gi)) || [];
    const isClappedNow = this.state.clapDetail?.post_id === postData?.id;
    const location = user?.profile?.city_obj?.name || "";
    const selectedSeedUserId = seedUsers?.selectedSeedUser?.id;

    const isPostedByCurrentUser =
      String(selectedSeedUserId) === String(user?.id);
    const is_unverified_job =
      postData?.data?.is_unverified_job || this.state.is_unverified_job;
    return (
      <div className="post">
        {this.state.showPostInfo && (
          <div className="post__meta">
            <span>{`POST ID - ${postData?.id}`}</span>
          </div>
        )}
        <div className="post__header">
          <Avatar
            className="post__avatar"
            alt={user?.full_name}
            src={this.state.photoUrl}
            onClick={() => this.props.fetchUserDetails(user?.id)}
          />
          <div className="post__headerInfo">
            <div className="post__headerMeta">
              <h3
                onClick={this.togglePostMeta}
              >{`${user?.full_name} - ${user?.id}`}</h3>
              <Badge clapCount={user?.clap_count} />
            </div>
            {workDetail?.experience_level?.level === "e" && (
              <p>{`${
                workDetail?.company_title ? workDetail.company_title : "Fresher"
              } ${
                workDetail && workDetail.company
                  ? `at ${workDetail && workDetail.company}`
                  : ""
              }`}</p>
            )}
            {workDetail &&
              workDetail.experience_level &&
              workDetail.experience_level.level === "f" && <p>Fresher</p>}
            {location && <p>{location}</p>}
          </div>
          {!isPostedByCurrentUser && (
            <IconButton onClick={this.handleHeaderReportClick}>
              <MoreVertIcon />
            </IconButton>
          )}
          <Menu
            id="report-user-menu"
            anchorEl={this.state.headerAnchorEl}
            keepMounted
            open={Boolean(this.state.headerAnchorEl)}
            onClose={this.handleHeaderReportClose}
          >
            <MenuItem onClick={this.handleReportUserClick}>
              Report user
            </MenuItem>
            <MenuItem onClick={this.handleUnverifiedPostClick}>
              Mark as unverified
            </MenuItem>
            <MenuItem onClick={this.handleUnverifiedAndReportPostClick}>
              Mark as unverified and report
            </MenuItem>
          </Menu>
        </div>
        {postDetail?.type?.toLowerCase() === "image" && postDetail.url && (
          <>
            {!this.state.postUrl && <div className="post__imgContainer"></div>}
            {this.state.postUrl && (
              <img
                className="post__image"
                src={this.state.postUrl}
                alt=""
                onClick={this.openMediaModal}
              />
            )}
          </>
        )}
        {postDetail?.type?.toLowerCase() === "video" && postDetail.url && (
          <div onClick={this.openMediaModal}>
            <video
              className="post__video"
              controls
              muted
              src={this.state.postUrl}
              type="video/mp4"
            ></video>
          </div>
        )}
        {postDetail?.type?.toLowerCase() === "audio" && (
          <div className="post__audio">
            <ReactAudioPlayer src={this.state.postUrl} controls />
          </div>
        )}
        {postDetail?.type?.toLowerCase() === "file" && postDetail.url && (
          <div className="post__file" onClick={this.openMediaModal}>
            <div className="post__fileIcon">
              <FileIcon />
            </div>
            <div className="post__fileInfo">
              <span>{postDetail.fileName || "unknown file"}</span>
              <span>{convertToMBs(postDetail.size)}</span>
            </div>
          </div>
        )}
        {is_unverified_job && <UnverifiedJobPost />}
        {message && (
          <Linkify>
            <h4 className="post__text">
              {ReactHtmlParser(this.renderMessage(taggedUsers, message))}
            </h4>
          </Linkify>
        )}
        <div className="post__links">
          {urlMatches &&
            urlMatches.map((link) => (
              <MicrolinkCard key={Math.random()} url={link} />
            ))}
        </div>

        <div className="post__info">
          <span onClick={this.openClapsList}>{`${clapCountList[postData?.id] ||
            0} ${clapCountList[postData?.id] > 1 ? "Claps" : "Clap"}`}</span>
          <span>{dateFormat(postData?.created_at)}</span>
        </div>
        <div className="post__icons">
          <Button
            size="small"
            startIcon={
              postData?.clapped || isClappedNow ? <ClappedIcon /> : <ClapIcon />
            }
            onClick={this.onClapPost}
          >
            Clap
          </Button>
          {!isPostedByCurrentUser && (
            <Button
              size="small"
              startIcon={<ReportIcon />}
              onClick={this.handleReportPostClick}
            >
              Report
            </Button>
          )}
        </div>
        {postData && (
          <div
            className={`post__reply ${this.props.thread?.post?.id ===
              postData?.id && "post__replyDisabled"}`}
          >
            <span onClick={this.handleThread}>
              {replyCountList[postData?.id] <= 1
                ? `view ${replyCountList[postData?.id]} reply`
                : `view all ${replyCountList[postData?.id]} replies`}
            </span>
          </div>
        )}
        {lastReply && <LastReply reply={lastReply} isThread={false} />}
        <PostReply repliedPost={postData} />

        {feedList?.postSuccess === postData?.id && (
          <p className="post__success">
            Your reply has been saved successfully!
          </p>
        )}
        {feedList?.postError === postData?.id && (
          <p className="post__error">Your reply was not saved!!</p>
        )}
        <ReportOptions
          isOpen={this.state.openReportOptions}
          onClose={this.closeReportOptionsModal}
          setReportOption={this.setReportOption}
          openReportMessageModal={this.openReportMessageModal}
          type={this.state.reportType}
        />
        <ReportMessage
          isOpen={this.state.openReportMessage}
          onClose={this.closeReportMessageModal}
          reportOption={this.state.reportOption}
          messageBody={this.state.messageBody}
          setMessageBody={this.setMessageBody}
          handleReport={
            this.state.type === "user"
              ? this.handleReportUserSubmitClick
              : this.handleReportPostSubmitClick
          }
          handleBack={this.handleBackToOptionsModal}
        />
        <SuccessToast
          open={this.state.showToast}
          handleClose={this.handleToastClose}
        />
        <MediaModal
          isOpen={this.state.openMedia}
          onClose={this.handleMediaClose}
          type={postDetail?.type?.toLowerCase()}
          mediaFile={this.state.postUrl}
          post={{
            avatar: this.state.photoUrl,
            name: user?.full_name,
            createdAt: dateFormat(postData?.created_at),
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  groups: state.groups,
  feedList: state.feedList,
  seedUsers: state.seedUsers,
  replyCountList: state.replyCountList,
  clapCountList: state.clapCountList,
  thread: state.thread,
});

export default connect(mapStateToProps, {
  setThreadOpen,
  clapPost,
  fetchClappersList,
  fetchUserDetails,
  updateClapCount,
})(PostComponent);
