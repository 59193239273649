import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { IconButton, Avatar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./MediaModal.css";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: "none",
    borderRadius: "8px",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "95%",
    height: "95%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  label: {
    fontWeight: 600,
    color: "white",
  },
  defaultLabel: {
    fontWeight: 600,
    color: "black",
  },
}));

const MediaModal = ({ isOpen, onClose, mediaFile, type, post }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="media-modal-title"
      aria-describedby="media-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div className="media__header">
          <div className="media__headerDetails">
            <Avatar src={post.avatar} />
            <div className="media__headerInfo">
              <span>{post.name}</span>
              <span className="media__timeStamp">{post.createdAt}</span>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="media__content">
          {type === "image" && (
            <img className="media__image" src={mediaFile} alt="" />
          )}
          {type === "video" && (
            <video
              className="media__image"
              src={mediaFile}
              alt=""
              controls
              type="video/mp4"
            />
          )}
          {type === "file" && (
            <iframe
              className="media__contentFile"
              src={mediaFile}
              title="pdf"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MediaModal;
