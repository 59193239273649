import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Avatar } from "@material-ui/core";
import { abbreviateNumber } from "../../utils/formatDate";
import SeedUser from "../seedUser/SeedUser";
import FeedMenu from "./FeedMenu";
import NotifPanel from "../notifications/NotifPanel";
import useFirebaseFileLoader from "../../utils/FirebaseFileLoader";
import { isMobile } from "../../utils/formatDate";

export default function FeedHeader() {
  const [anchorEl, setAnchorEl] = useState(null);
  const feedList = useSelector((state) => state.feedList);
  const selectedFeed = feedList?.selectedFeed;
  const selectedSeedUser = useSelector(
    (state) => state.seedUsers?.selectedSeedUser
  );
  const [groupProfile, loading] = useFirebaseFileLoader(selectedFeed?.photo);
  const isMobileDevice = isMobile();
  const feedName = isMobileDevice
    ? selectedFeed?.name?.length <= 12
      ? selectedFeed?.name
      : `${selectedFeed?.name?.slice(0, 12)}..`
    : selectedFeed?.name;
  const handleNotifClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="feed__header">
      <FeedMenu />
      {selectedFeed && (
        <div className="feed__headerLeft">
          <div className="feed__headerDetail">
            <Avatar
              className="feed__avatar"
              alt="feed"
              src={selectedFeed?.groupProfileImage || groupProfile}
            />
            <div className="feed__headerInfo">
              <h3>{feedName}</h3>
              <p>{`${abbreviateNumber(selectedFeed?.member_count)} members`}</p>
            </div>
          </div>
        </div>
      )}
      {selectedFeed && (
        <div className="feed__headerRight">
          {selectedSeedUser && <SeedUser />}
          {isMobileDevice && (
            <NotifPanel
              notifAnchorEl={anchorEl}
              handleNotifClick={handleNotifClick}
              handleClose={handleClose}
            />
          )}
        </div>
      )}
    </div>
  );
}
