import axios from "axios";
import APIConfig from "./APIConfig";

import { GetCurrentStorage } from "./LocalStorage";

axios.defaults.baseURL = APIConfig.BASE_PATH;
axios.defaults.headers.common["Content-Type"] = "application/json";

// Send an HTTP GET
export const Get = async (url, params) => {
  axios.defaults.headers.common["Authorization"] =
    "Token " + GetCurrentStorage("userToken");
  axios.defaults.headers.common["Content-Type"] = "application/json";

  try {
    const response = await axios.get(url, { params: params });
    return response && response.data ? response.data : null;
  } catch (error) {
    console.error("GET:" + url, error);
    return {
      error: {
        errors: ["Something went wrong!"],
      },
    };
  }
};

// Send an HTTP POST
export const Post = async (url, postBody) => {
  if (GetCurrentStorage("userToken")) {
    axios.defaults.headers.common["Authorization"] =
      "Token " + GetCurrentStorage("userToken");
  }
  try {
    const response = await axios.post(url, postBody);
    return response && response.data ? response.data : null;
  } catch (error) {
    console.error("POST:" + url, error);
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return {
        error: {
          errors: ["Something went wrong!"],
        },
      };
    }
  }
};

export const OpenPost = async (url, postBody) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = null;
  try {
    const response = await axios.post(url, postBody);
    return response && response.data ? response.data : null;
  } catch (error) {
    console.error("POST:" + url, error);
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return {
        error: {
          errors: ["Something went wrong!"],
        },
      };
    }
  }
};
export const GoogleGet = async (url, params) => {
  delete axios.defaults.headers.common;
  try {
    const response = await axios.get(url, { params });
    return response ? response : null;
  } catch (error) {
    console.error("Get:" + url, error);
  }
};
