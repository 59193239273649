import {
  OPEN_CLAPPER_LIST,
  CLOSE_CLAPPER_LIST,
  UPDATE_CLAPPERS_LIST,
} from "../constants/types";

const initialState = {
  clappers: [],
  isOpen: false,
  totalClaps: 0,
  loading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case OPEN_CLAPPER_LIST:
      return {
        ...state,
        isOpen: true,
        loading: true,
      };
    case CLOSE_CLAPPER_LIST:
      return {
        ...state,
        clappers: [],
        isOpen: false,
        totalClaps: 0,
        loading: false,
      };
    case UPDATE_CLAPPERS_LIST:
      return {
        ...state,
        clappers: payload?.users || [],
        totalClaps: payload?.clap_count || 0,
        loading: false,
      };

    default:
      return state;
  }
};
