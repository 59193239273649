import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import { IconButton } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import { postMessage } from "../../actions/create-new-post.action";
import Collapse from "@material-ui/core/Collapse";
import Popover from "@material-ui/core/Popover";
import {
  closeTagSuggestions,
  fetchTagSuggestionsNew,
} from "../../actions/update-tag-suggestion.action";
import MediaUploads from "../mediaUploads/MediaUploads";
import PostReplyTagSuggestions from "./PostReplyTagSuggestions";
import Tag from "../tag/Tag";
import "./PostReply.css";
import { isMobile } from "../../utils/formatDate";
import PasteToPreview from "../pasteToUpload";
import { storage } from "../../firebase";

export default function PostReply({ repliedPost }) {
  const dispatch = useDispatch();
  const inputEl = useRef(null);
  const feedList = useSelector((state) => state.feedList);
  const tags = useSelector((state) => state.tags);
  const tagSuggestionList = tags?.tagSuggestions;
  const feedTagSuggestions = tags?.feedTagSuggestions;
  const tagList = feedTagSuggestions;
  const selectedFeed = feedList?.selectedFeed;
  const { selectedSeedUser } = useSelector((state) => state.seedUsers);
  const [filteredList, setFilteredList] = useState([]);
  const [replyText, setReplyText] = useState("");
  const [emojiPanel, setEmojiPanel] = useState(false);
  const [taggedMembers, setTaggedMembers] = useState([]);
  const [isMention, setIsMention] = useState(false);
  const [showTagSuggestions, setShowTagSuggestions] = useState(false);
  const [showFeedTagSuggestions, setShowFeedTagSuggestions] = useState(false);
  const [term, setTerm] = useState("");
  const tagCount = tagSuggestionList?.length;
  const id = showTagSuggestions ? "tag-popover" : undefined;
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverActions = useRef();
  const [blobURL, setBlobURL] = useState("");
  const [blob, setBlob] = useState(null);
  const [caption, setCaption] = useState("");
  const captionInputRef = useRef(null);
  useEffect(() => {
    if (popoverActions.current) {
      popoverActions.current.updatePosition();
    }
  }, [tagCount]);

  const emojiPanelOpenClose = () => {
    setEmojiPanel(!emojiPanel);
  };
  const onEmojiClick = (event, emojiObject) => {
    let newMessage = replyText + emojiObject.emoji;
    setReplyText(newMessage);
  };
  const [checked, setChecked] = useState(false);

  const handleCollapse = () => {
    setChecked((prev) => !prev);
  };
  const onChange = (e, isCaption = false) => {
    e.preventDefault();
    if (isCaption) {
      setCaption(e.target.value);
    } else {
      setReplyText(e.target.value);
    }
    const groupId = feedList?.selectedFeed?.id;
    let check = e.target.value.lastIndexOf("@");
    let trimValue = e.target.value.trim();
    if (check > -1) {
      let matchingData = e.target.value.substring(check + 1, trimValue.length);
      let existingTagIndex = taggedMembers.indexOf(matchingData);
      if (matchingData.length <= 2 && isMention) {
        setTerm(matchingData);
        setShowFeedTagSuggestions(true);
        setShowTagSuggestions(false);
        setAnchorEl(inputEl.current);
        filterTags(matchingData);
      }
      if (
        matchingData &&
        matchingData.length >= 0 &&
        isMention &&
        existingTagIndex === -1
      ) {
        let params = {
          groupId: groupId,
          keyword: matchingData,
          limit: 30,
          postId: repliedPost.id,
        };
        dispatch(fetchTagSuggestionsNew(params, selectedSeedUser?.id));
        if (!showTagSuggestions) {
          setTerm("");
          setShowFeedTagSuggestions(false);
          setShowTagSuggestions(true);
          setAnchorEl(inputEl.current);
        }
      }
    } else {
      setTerm("");
      setShowFeedTagSuggestions(false);
      setShowTagSuggestions(false);
      setAnchorEl(null);
    }
  };
  const onKeyDown = (e) => {
    let lastCharEntered =
      e.target.value.charAt(e.target.value.length - 1) || "";
    if (!isMobile() && e.key === "Enter" && !e.shiftKey) {
      sendMessage(e);
    }
    if (e.key === "@" || lastCharEntered === "@") {
      setIsMention(true);
      setTerm("");
      setShowFeedTagSuggestions(true);
    }
  };
  const sendMessage = (e) => {
    e.preventDefault();
    let tagList = [];
    let tagUsersList = [];
    taggedMembers.forEach((taggedMember) => {
      if (
        replyText.substring(taggedMember.start, taggedMember.end + 1) ===
        `@${taggedMember.fullName}`
      ) {
        tagList.push({
          end: taggedMember.end,
          fullName: taggedMember.fullName,
          start: taggedMember.start,
          userId: taggedMember.userId,
        });
        tagUsersList.push(taggedMember.userId);
      } else if (replyText.indexOf(taggedMember.fullName) > -1) {
        let start, end;
        start = replyText.indexOf(`@${taggedMember.fullName}`);
        end = start + taggedMember.fullName.length;
        tagList.push({
          end: end,
          fullName: taggedMember.fullName,
          start: start,
          userId: taggedMember.userId,
        });
        tagUsersList.push(taggedMember.userId);
      }
    });
    if (replyText !== "" && replyText.trim() !== "") {
      const urlMatches = replyText.match(/\b(http|https)?:\/\/\S+/gi);
      let post = {};
      post = {
        data: {
          isDeeplink: false,
          isRichLink: urlMatches ? true : false,
          taggedMembersList: tagList,
          text: replyText,
          repliedPost: repliedPost,
          source: "web",
          type: "TEXT",
        },
        group: selectedFeed?.id,
        replied_to: repliedPost?.id,
        tagged_users: tagUsersList,
        type: "text",
        impersonate_user_id: selectedSeedUser?.id,
        send_notification: false,
      };
      dispatch(postMessage(post, selectedFeed?.id, "reply"));
    }
    setReplyText("");
    setEmojiPanel(false);
    setTaggedMembers([]);
  };
  const selectTaggedMember = (taggedMember) => {
    let previousMessage = replyText;
    let lastIndex = previousMessage.lastIndexOf("@");
    setReplyText(
      previousMessage.substring(0, lastIndex) +
        "@" +
        taggedMember.full_name +
        " "
    );
    let start = lastIndex;
    let end = start + taggedMember.full_name.length;
    setTaggedMembers([
      ...taggedMembers,
      {
        start: start,
        end: end,
        fullName: taggedMember.full_name,
        userId: taggedMember.id,
      },
    ]);
    setShowTagSuggestions(false);
    setIsMention(false);
    setAnchorEl(null);
    dispatch(closeTagSuggestions());
  };
  const handlePopoverClose = () => {
    setShowTagSuggestions(false);
    setShowFeedTagSuggestions(false);
    dispatch(closeTagSuggestions());
  };
  const filterTags = (matchingData) => {
    let filterTagList = [];
    let term = matchingData;

    tagList &&
      tagList.forEach((tag) => {
        if (tag?.full_name?.toLowerCase().indexOf(term.toLowerCase()) !== -1) {
          filterTagList.push(tag);
        }
      });
    if (term !== "") {
      setFilteredList(filterTagList);
    } else {
      setFilteredList(tagList);
    }
  };
  const setFocusOnPaste = (e) => {
    captionInputRef.current.focus();
  };
  const clearPastedImagePreview = () => {
    setBlob(null);
    setBlobURL("");
    setCaption("");
  };
  const handlePaste = async (e) => {
    // Get the data of clipboard
    const clipboardItems = e.clipboardData.items;
    const items = [].slice.call(clipboardItems).filter(function(item) {
      // Filter the image items only
      return item.type.indexOf("image") !== -1;
    });
    if (items.length === 0) {
      return;
    }

    const item = items[0];
    // Get the blob of image
    const blobObject = await item.getAsFile();
    const blobURLString = URL.createObjectURL(blobObject);
    setBlob(blobObject);
    setBlobURL(blobURLString);
    setFocusOnPaste(e);
  };
  const handleUpload = (imageBlob, fileName) => {
    const uploadTask = storage
      .child(`attachment/community/image/${fileName}`)
      .put(imageBlob);
    uploadTask.on("state_changed", (error) => {
      console.log(error);
    });
  };

  const handleSend = async (e, imageBlob) => {
    e.preventDefault();
    // upload
    let mediaFileName = selectedSeedUser?.id + "_" + new Date().getTime();
    setTimeout(handleUpload(imageBlob, mediaFileName), 500);
    // Update
    let tagList = [];
    let tagUsersList = [];
    let urlMatches = "";
    taggedMembers.forEach((taggedMember) => {
      if (
        caption.substring(taggedMember.start, taggedMember.end + 1) ===
        `@${taggedMember.fullName}`
      ) {
        tagList.push({
          end: taggedMember.end,
          fullName: taggedMember.fullName,
          start: taggedMember.start,
          userId: taggedMember.userId,
        });
        tagUsersList.push(taggedMember.userId);
      } else if (caption.indexOf(taggedMember.fullName) > -1) {
        let start, end;
        start = caption.indexOf(`@${taggedMember.fullName}`);
        end = start + taggedMember.fullName.length;
        tagList.push({
          end: end,
          fullName: taggedMember.fullName,
          start: start,
          userId: taggedMember.userId,
        });
        tagUsersList.push(taggedMember.userId);
      }
    });
    if (caption !== "" && caption.trim() !== "") {
      urlMatches = caption.match(/\b(http|https)?:\/\/\S+/gi);
    }
    let post = {
      data: {
        isDeeplink: false,
        isRichLink: urlMatches ? true : false,
        taggedMembersList: tagList,
        size: blob?.size,
        caption: caption,
        repliedPost: repliedPost,
        source: "web",
        type: "IMAGE",
        url: `attachment/community/image/${mediaFileName}`,
      },
      group: selectedFeed?.id,
      tagged_users: tagUsersList,
      type: "image",
      impersonate_user_id: selectedSeedUser?.id,
      send_notification: false,
    };
    dispatch(postMessage(post, selectedFeed?.id, "reply"));
    clearPastedImagePreview();
  };
  return (
    <div className="post__replyCollapse">
      <PasteToPreview
        captionInputRef={captionInputRef}
        caption={caption}
        blob={blob}
        blobURL={blobURL}
        clearPastedImagePreview={clearPastedImagePreview}
        handleSend={handleSend}
        onChange={onChange}
      />
      <form className="post__commentBox" ref={inputEl}>
        <textarea
          className="post__input"
          placeholder="Reply.."
          value={replyText}
          onClick={handleCollapse}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onPaste={(e) => handlePaste(e)}
        />
        <IconButton
          onClick={(e) => {
            sendMessage(e);
          }}
        >
          <SendIcon />
        </IconButton>
      </form>
      {checked && <hr />}
      <Collapse in={checked}>
        <div className="feed__footerSeed">
          <div className="feed__footerIcons">
            <IconButton onClick={emojiPanelOpenClose}>
              <InsertEmoticonIcon />
            </IconButton>
            {emojiPanel && (
              <div className="message__footerEmoji">
                <Picker
                  onEmojiClick={onEmojiClick}
                  skinTone={SKIN_TONE_MEDIUM_DARK}
                />
              </div>
            )}
            <MediaUploads type="thread" repliedPost={repliedPost} />
          </div>
        </div>
      </Collapse>
      {showFeedTagSuggestions && (
        <PostReplyTagSuggestions
          showFeedTagSuggestions={showFeedTagSuggestions}
          setTaggedMember={selectTaggedMember}
          handlePopoverClose={handlePopoverClose}
          filteredList={filteredList}
        />
      )}
      <Popover
        id=""
        open={showTagSuggestions}
        onClose={handlePopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 200, left: 900 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableAutoFocus
        disableEnforceFocus
        PaperProps={{
          style: { maxHeight: 400 },
        }}
      >
        {tagSuggestionList &&
          tagSuggestionList.map((tag) => (
            <Tag key={tag?.id} setTaggedMember={selectTaggedMember} tag={tag} />
          ))}
      </Popover>
    </div>
  );
}
