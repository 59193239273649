import React, { Component } from "react";
import { SearchOutlined } from "@material-ui/icons";

export default class GroupSearch extends Component {
  handleChange = (e) => {
    this.props.searchGroup(e.target.value);
  };

  render() {
    return (
      <>
        <div className="sidebar__search">
          <SearchOutlined />
          <input
            placeholder="Search By group..."
            onChange={this.handleChange}
          />
        </div>

        <div>
          <label for="groupName">Group Name</label>
          <input
            type="radio"
            id="groupName"
            name="fav_language"
            value="groupName"
            checked={this.props.searchBy === "groupName"}
            onChange={(e) => this.props.handleSearchByChange(e)}
          />
          <label for="postId">Post Id</label>
          <input
            type="radio"
            id="postId"
            name="fav_language"
            value="postId"
            checked={this.props.searchBy === "postId"}
            onChange={(e) => this.props.handleSearchByChange(e)}
          />
        </div>
      </>
    );
  }
}
