import React, { Component } from "react";
import { connect } from "react-redux";
import Post from "../post/Post";
import Spinner from "../dashboard/Spinner";
import { Button, IconButton } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { fetchMoreFeed } from "../../actions/update-feed-action";

class FeedList extends Component {
  componentDidMount() {
    this.handleScrollToLastMessage();
  }
  componentDidUpdate() {
    const { feedList } = this.props;
    if (
      !feedList.fetchedMore && feedList.fetchedFirstLoad
    ) {
      this.handleScrollToLastMessage();
    } else if (
      feedList.fetchedMore && !feedList.hasMore
    ) {
      this.handleScrollToFirstMessage();
    } else if (feedList.fetchedMore && feedList.hasMore && !feedList.isReply) {
      this.handleScrollOnLoadMore(feedList.scrollTo);
    }
  }
  handleScrollToLastMessage = () =>
    (this.feedBodyEl.scrollTop = this.feedBodyEl.scrollHeight);
  handleScrollToFirstMessage = () =>
    // eslint-disable-next-line no-self-assign
    (this.feedBodyEl.scrollTop = this.feedBodyEl.scrollTop);
  handleScrollOnLoadMore = (scrollTo) => {
    this.feedBodyEl.scrollTop = this.feedBodyEl.scrollHeight - scrollTo;
  };
  fetchMoreData = () => {
    let { feedList } = this.props;
    let groupId = feedList && feedList.selectedFeed && feedList.selectedFeed.id;
    let scrollTo = this.feedBodyEl.scrollHeight;
    this.props.fetchMoreFeed(groupId, scrollTo);
  };
  render() {
    const { feedList } = this.props;
    const feed = feedList && feedList.feed;

    return (
      <>
        <div
          className="feed__body"
          ref={(component) => (this.feedBodyEl = component)}
        >
          {feedList.loadingFeed && <Spinner />}
          {!feedList.loadingFeed && feedList && feedList.selectedFeed && (
            <>
              {feedList.hasMore && (
                <div className="feed_bodyButton" onClick={this.fetchMoreData}>
                  <Button variant="contained">Show more posts</Button>
                </div>
              )}
              {feedList.fetchedMore && !feedList.hasMore && (
                <div className="feed_bodyButton">
                  <p>This is the end of the feed</p>
                </div>
              )}
              {feed &&
                feed.length > 0 &&
                feed.map((post, index) => (
                  <Post
                    key={post.id}
                    id={post.id}
                    postData={post}
                    postIndex={index}
                  />
                ))}
              <IconButton onClick={this.handleScrollToLastMessage}>
                <ArrowDownwardIcon />
              </IconButton>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  feedList: state.feedList,
});

export default connect(mapStateToProps, { fetchMoreFeed })(FeedList);
