import React from "react";
import {
  abbreviateNumber,
  generateClapsColors,
  generateClapsLevel,
} from "../../utils/formatDate";
import "./Badge.css";

const Badge = ({ clapCount }) => {
  const badgeColor = {
    backgroundColor: `${generateClapsColors(clapCount)}`,
  };
  const label = generateClapsLevel(clapCount)?.toUpperCase();
  const clapCountFormatted = abbreviateNumber(parseInt(clapCount));
  return (
    <>
      {clapCount >= 100 && clapCount < 15000 && (
        <span className="badge" style={badgeColor}>
          {label}
        </span>
      )}
      {clapCount >= 15000 && (
        <div className="badge__container">
          <div className="badge__count">{clapCountFormatted}</div>
          <div className="badge__label">{label}</div>
        </div>
      )}
    </>
  );
};

export default Badge;
