import React, { Component } from "react";
import { Avatar, Badge } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { storage } from "../../firebase";
import { connect } from "react-redux";
import { fetchFeed, setSelectedFeed } from "../../actions/update-feed-action";
import {
  updateLastSeenAt,
  resetUnreadCount,
} from "../../actions/update-groups-list.action";
import { setThreadClose } from "../../actions/create-new-post.action";
import {
  setSelectedSeedUser,
  setGroupsMobileClose,
} from "../../actions/auth.action";
import { closeFeedTagSuggestions } from "../../actions/update-tag-suggestion.action";

import Mixpanel from "../../utils/Mixpanel";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#f85963",
    color: "white",
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "2px solid currentColor",
  },
}))(Badge);

class Group extends Component {
  state = {
    groupProfileImage: "",
  };
  componentDidMount() {
    storage
      .child(`${this.props.group.photo}`)
      .getDownloadURL()
      .then((url) => {
        this.setState({ groupProfileImage: url });
      })
      .catch((error) => {});
  }
  fetchFeedForGroup = () => {
    this.props.closeFeedTagSuggestions();
    this.props.setSelectedSeedUser(this.props.auth?.user);
    this.props.setThreadClose();
    this.props.setGroupsMobileClose();
    this.props.fetchFeed(this.props.group.id, true);
    this.props.setSelectedFeed({
      ...this.props.group,
      groupProfileImage: this.state.groupProfileImage,
    });
    this.props.updateLastSeenAt(this.props.group.id);
    this.props.resetUnreadCount(this.props.group.id);
    Mixpanel.track("CHAT_GROUP_REFRESH", {
      GROUP_ID: this.props.group.id,
      SENDER: "web",
    });
  };
  render() {
    const { group, groupDetails } = this.props;
    const unreadCount = groupDetails?.unreadCount;
    const groupLastSeen = unreadCount && unreadCount[group?.id];
    const { groupProfileImage } = this.state;
    return (
      <div className="sidebarChat" onClick={this.fetchFeedForGroup}>
        {!groupLastSeen && <Avatar alt={group.name} src={groupProfileImage} />}
        {groupLastSeen > 0 && (
          <StyledBadge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            variant="dot"
            overlap="circle"
            badgeContent=" "
          >
            <Avatar alt={group?.name} src={groupProfileImage} />
          </StyledBadge>
        )}
        <div className="sidebarChat__info">
          <h2>{group?.name}</h2>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  groupDetails: state.groups,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setThreadClose,
  fetchFeed,
  setSelectedFeed,
  updateLastSeenAt,
  resetUnreadCount,
  setSelectedSeedUser,
  setGroupsMobileClose,
  closeFeedTagSuggestions,
})(Group);
