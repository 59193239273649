import {
  FETCH_TAG_SUGGESTIONS,
  FETCH_FEED_TAG_SUGGESTIONS,
  FETCH_THREAD_TAG_SUGGESTIONS,
  CLOSE_FEED_TAG_SUGGESTIONS,
  CLOSE_THREAD_TAG_SUGGESTIONS,
  CLOSE_TAG_SUGGESTIONS,
} from "../constants/types";
import { Get, Post } from "../utils/api";
import APIConfig from "../utils/APIConfig";
import { filterDuplicates, sortBy } from "../utils/formatDate";

export const fetchTagSuggestions = (param, groupId) => (dispatch) => {
  if (groupId) {
    Get(APIConfig.BASE_PATH + `v2/groups/${groupId}/members/`, param)
      .then(({ results }) => {
        dispatch({
          type: FETCH_TAG_SUGGESTIONS,
          payload: results,
        });
      })
      .catch((error) => console.error(error));
  }
};

export const closeTagSuggestions = () => (dispatch) => {
  dispatch({
    type: CLOSE_TAG_SUGGESTIONS,
    payload: true,
  });
};
export const closeFeedTagSuggestions = () => (dispatch) => {
  dispatch({
    type: CLOSE_FEED_TAG_SUGGESTIONS,
    payload: true,
  });
};
export const closeThreadTagSuggestions = () => (dispatch) => {
  dispatch({
    type: CLOSE_THREAD_TAG_SUGGESTIONS,
    payload: true,
  });
};

export const fetchFeedTagSuggestions = (posts) => (dispatch, getState) => {
  const { tags } = getState();
  const feedTagSuggestions = tags?.feedTagSuggestions;
  let feedTags = [];
  posts.forEach((post) => {
    let postUser = {
      id: post?.user?.id,
      full_name: post?.user?.full_name,
      photo_firebase_path: post?.user?.photo_firebase_path,
    };
    let lastReplyUser = {
      id: post?.last_replied_post?.user?.id,
      full_name: post?.last_replied_post?.user?.full_name,
      photo_firebase_path: post?.last_replied_post?.user?.photo_firebase_path,
    };

    feedTags.push(postUser);
    if (post?.last_replied_post?.user?.id) {
      feedTags.push(lastReplyUser);
    }
  });
  let updatedTagSuggestions = filterDuplicates([
    ...feedTagSuggestions,
    ...feedTags,
  ])?.sort(sortBy("full_name"));

  dispatch({
    type: FETCH_FEED_TAG_SUGGESTIONS,
    payload: updatedTagSuggestions,
  });
};

export const fetchThreadTagSuggestions = (moreReplyList) => (
  dispatch,
  getState
) => {
  const { thread, tags } = getState();
  const replies = [...thread?.replyList, ...moreReplyList];
  const feedTagSuggestions = tags?.feedTagSuggestions;
  let threadTags = [];

  replies.forEach((reply) => {
    let postUser = {
      id: reply?.user?.id,
      full_name: reply?.user?.full_name,
      photo_firebase_path: reply?.user?.photo_firebase_path,
    };
    threadTags.push(postUser);
  });
  let updatedTagSuggestions = filterDuplicates([
    ...feedTagSuggestions,
    ...threadTags,
  ])?.sort(sortBy("full_name"));

  dispatch({
    type: FETCH_THREAD_TAG_SUGGESTIONS,
    payload: updatedTagSuggestions,
  });
};


export const fetchTagSuggestionsNew =(param, userId) => (dispatch) => {
  if (userId) {
    Get(APIConfig.TAGGING_PATH + `${userId}/tagging-suggestions`, param)
      .then(({ results }) => {
        dispatch({
          type: FETCH_TAG_SUGGESTIONS,
          payload: results,
        });
      })
      .catch((error) => console.error(error));
  }
};
