import React, { Component } from "react";
import { connect } from "react-redux";
import MicRecorder from "mic-recorder-to-mp3";
import { IconButton } from "@material-ui/core";
import AlbumIcon from "@material-ui/icons/Album";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ms from "pretty-ms";
import "./AudioRecord.css";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default class AudioRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: "",
      isBlocked: false,
      time: 0,
      start: 0,
    };
  }

  start = () => {
    if (this.state.isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({
            isRecording: true,
            time: this.state.time,
            start: Date.now() - this.state.time,
          });
          this.timer = setInterval(
            () =>
              this.setState({
                time: Date.now() - this.state.start,
              }),
            1
          );
        })
        .catch((e) => console.error(e));
    }
  };

  stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        this.setState({ blobURL, isRecording: false });
        this.props.handleRecordInput(blob);
        clearInterval(this.timer);
      })
      .catch((e) => console.log(e));
  };

  componentDidMount() {
    navigator.getUserMedia(
      { audio: true },
      () => {
        this.setState({ isBlocked: false });
      },
      () => {
        this.setState({ isBlocked: true });
      }
    );
  }

  render() {
    return (
      <div className="audio__container">
        <div className="audio__buttons">
          <IconButton onClick={this.start} disabled={this.state.isRecording}>
            <AlbumIcon />
          </IconButton>
          <span className="audio__timer">
            {ms(this.state.time, {
              colonNotation: true,
              secondsDecimalDigits: 0,
            })}
          </span>
          <IconButton onClick={this.stop} disabled={!this.state.isRecording}>
            <CheckCircleIcon />
          </IconButton>
        </div>
        {!this.props.loading && (
          <audio src={this.state.blobURL} controls="controls" />
        )}
      </div>
    );
  }
}
