import {
  FETCHED_SEED_MEMBERS,
  SELECTED_SEED_MEMBER,
  UPDATE_SEED_PROFILE_URL,
  RESET_SEED_MEMBERS,
} from "../constants/types";

const initialState = {
  users: [JSON.parse(localStorage.getItem("user"))],
  selectedSeedUser: JSON.parse(localStorage.getItem("user")),
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_SEED_MEMBERS:
      return {
        ...state,
        users: [JSON.parse(localStorage.getItem("user"))],
        selectedSeedUser: JSON.parse(localStorage.getItem("user")),
      };
    case FETCHED_SEED_MEMBERS:
      return {
        ...state,
        users: [state.selectedSeedUser, ...payload],
        selectedSeedUser: JSON.parse(localStorage.getItem("user")),
      };
    case SELECTED_SEED_MEMBER:
      return {
        ...state,
        selectedSeedUser: payload,
      };
    case UPDATE_SEED_PROFILE_URL:
      return {
        ...state,
        selectedSeedUser: { ...state.selectedSeedUser, profileUrl: payload },
      };
    default:
      return state;
  }
};
