import {
  INIT_CLAP_COUNT,
  UPDATE_POST_CLAP_COUNT,
  RESET_CLAP_COUNT,
} from "../constants/types";

export const initClapCount = (feedClapCount) => (dispatch) => {
  dispatch({
    type: INIT_CLAP_COUNT,
    payload: feedClapCount,
  });
};

export const updateClapCount = (postId, count) => (dispatch) => {
  dispatch({
    type: UPDATE_POST_CLAP_COUNT,
    payload: { postId, count },
  });
};

export const resetClapCount = () => (dispatch) => {
  dispatch({
    type: RESET_CLAP_COUNT,
    payload: true,
  });
};
