import React from "react";

const PasteToPreview = ({
  blob,
  blobURL,
  caption,
  onChange,
  clearPastedImagePreview,
  handleSend,
  captionInputRef,
}) => {
  if (blobURL) {
    return (
      <div className="pasted__image--container">
        <div className="preview__container--control">
          <p>Pasted Image Preview</p>
          <button
            className="cancel__pasted-image--preview"
            onClick={clearPastedImagePreview}
          >
            Cancel
          </button>
        </div>
        <div className="preview__container">
          <img src={blobURL} alt="preview" />
          <div className="preview__caption--container">
            <textarea
              value={caption}
              onChange={(e) => onChange(e, true)}
              placeholder="Add caption here"
              ref={captionInputRef}
            />
            <button
              onClick={(e) => handleSend(e, blob)}
              className="send__preview--message"
            >
              Send Image
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null
};

export default PasteToPreview;
