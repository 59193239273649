import {
  GROUP_LOADING_STARTED,
  UPDATE_GROUPS_LIST,
  INIT_GROUP_LAST_SEEN_AT,
  UPDATE_GROUP_LAST_SEEN_AT,
  FETCHED_GROUP_NOTIFICATION,
  RESET_UNREAD_COUNT,
} from "../constants/types";

const initialState = {
  groupList: [],
  loadingGroups: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GROUP_LOADING_STARTED:
      return {
        ...state,
        loadingGroups: true,
      };
    case UPDATE_GROUPS_LIST:
      return {
        ...state,
        groupList: payload,
        loadingGroups: false,
      };
    case INIT_GROUP_LAST_SEEN_AT:
      return {
        ...state,
        groupsLastSeenAt: payload,
      };
    case UPDATE_GROUP_LAST_SEEN_AT:
      return {
        ...state,
        groupsLastSeenAt: { ...state.groupsLastSeenAt, [payload.id]: payload },
      };
    case FETCHED_GROUP_NOTIFICATION:
      return {
        ...state,
        unreadCount: payload,
      };
    case RESET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: { ...state.unreadCount, [state.unreadCount[payload]]: 0 },
      };
    default:
      return state;
  }
};
