import {
  FETCH_TAG_SUGGESTIONS,
  FETCH_FEED_TAG_SUGGESTIONS,
  FETCH_THREAD_TAG_SUGGESTIONS,
  CLOSE_TAG_SUGGESTIONS,
  CLOSE_FEED_TAG_SUGGESTIONS,
  CLOSE_THREAD_TAG_SUGGESTIONS,
} from "../constants/types";

const initialState = {
  tagSuggestions: [],
  feedTagSuggestions: [],
  threadTagSuggestions: [],
  showTagSuggestions: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TAG_SUGGESTIONS:
      return {
        ...state,
        tagSuggestions: payload,
        showTagSuggestions: true,
      };
    case CLOSE_TAG_SUGGESTIONS:
      return {
        ...state,
        tagSuggestions: [],
        showTagSuggestions: false,
      };
    case FETCH_FEED_TAG_SUGGESTIONS:
      return {
        ...state,
        feedTagSuggestions: payload,
        showTagSuggestions: true,
      };
    case FETCH_THREAD_TAG_SUGGESTIONS:
      return {
        ...state,
        threadTagSuggestions: payload,
        showTagSuggestions: true,
      };
    case CLOSE_FEED_TAG_SUGGESTIONS:
      return {
        ...state,
        tagSuggestions: [],
        feedTagSuggestions: [],
        threadTagSuggestions: [],
        showTagSuggestions: false,
      };
    case CLOSE_THREAD_TAG_SUGGESTIONS:
      return {
        ...state,
        tagSuggestions: [],
        threadTagSuggestions: [],
        showTagSuggestions: false,
      };
    default:
      return state;
  }
};
