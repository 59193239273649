import React from "react";
import { useSelector } from "react-redux";
import Notifications from "../notifications/Notifications";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { IconButton, Menu, MenuItem } from "@material-ui/core";

const NotifBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#f85963",
    color: "white",
    right: -3,
    top: 13,
    padding: "0 4px",
  },
}))(Badge);

export default function NotifPanel({
  notifAnchorEl,
  handleNotifClick,
  handleClose,
}) {
  const notifications = useSelector((state) => state.notifications);
  const notificationList = notifications?.notificationList;
  const notifCount = notifications?.newCount;

  return (
    <>
      <IconButton onClick={handleNotifClick}>
        <NotifBadge badgeContent={notifCount}>
          <NotificationsIcon />
        </NotifBadge>
      </IconButton>
      <Menu
        id="notif-menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={notifAnchorEl}
        keepMounted
        open={Boolean(notifAnchorEl)}
        onClose={handleClose}
      >
        {notificationList?.map((notif) => (
          <MenuItem key={notif?.id}>
            <Notifications notif={notif} onClose={handleClose} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
