import React, { Component } from "react";
import { connect } from "react-redux";
import "./GroupList.css";
import Spinner from "../dashboard/Spinner";
import Group from "./Group";
import GroupSearch from "./GroupSearch";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { Avatar, IconButton, Menu, MenuItem } from "@material-ui/core";
import NotifPanel from "../notifications/NotifPanel";
import { GetCurrentStorage } from "../../utils/LocalStorage";
import { logout } from "../../actions/auth.action";
import { isMobile } from "../../utils/formatDate";

class GroupList extends Component {
  state = {
    infoAnchorEl: null,
    notifAnchorEl: null,
    filteredList: [],
    searchBy: "groupName",
  };
  handleSearchByChange = (e) => {
    this.setState({ searchBy: e.target.value });
  };
  handleMoreClick = (e) => {
    this.setState({ infoAnchorEl: e.currentTarget, notifAnchorEl: null });
  };
  handleNotifClick = (e) => {
    this.setState({ notifAnchorEl: e.currentTarget, infoAnchorEl: null });
  };
  handleClose = () => {
    this.setState({ infoAnchorEl: null, notifAnchorEl: null });
  };
  handleLogout = () => {
    this.props.logout();
    localStorage.clear();
    window.location.reload();
  };
  renderList = () => {
    const { groups } = this.props;
    if (groups.loadingGroups) {
      return <Spinner />;
    }
    if (
      !groups.loadingGroups &&
      groups.groupList &&
      groups.groupList.length > 0
    ) {
      return groups?.groupList?.map((group) => (
        <Group key={group.id} group={group} />
      ));
    }
  };
  filterGroups = (term) => {
    if (this.state.searchBy === "groupName") {
      const groups = this.props.groups;
      const groupList = groups?.groupList;
      let filteredList = [];
      groupList &&
        groupList.forEach((group) => {
          if (group?.name.toLowerCase().indexOf(term.toLowerCase()) !== -1) {
            filteredList.push(group);
          }
        });
      this.setState({ filteredList });
    } else {
      this.props.fetchPostByPostId(term);
    }
  };
  renderFilteredList = () => {
    return this.state.filteredList.map((group) => (
      <Group key={group.id} group={group} />
    ));
  };

  render() {
    const { auth } = this.props;
    const user = auth?.user;
    const mobile = GetCurrentStorage("mobile");
    const isMobileDevice = isMobile();
    return (
      <div className="sidebar">
        <div className="sidebar__header">
          <div className="sidebar__logo">
            <span className="sidebar__logoStart">apna</span>
            <span className="sidebar__logoEnd">group</span>
          </div>
          <div className="sidebar__headerRight">
            {!isMobileDevice && (
              <NotifPanel
                notifAnchorEl={this.state.notifAnchorEl}
                handleNotifClick={this.handleNotifClick}
                handleClose={this.handleClose}
              />
            )}
            <IconButton onClick={this.handleMoreClick}>
              <MoreVertIcon />
            </IconButton>
          </div>
          <Menu
            id="info-menu"
            anchorEl={this.state.infoAnchorEl}
            keepMounted
            open={Boolean(this.state.infoAnchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem>
              <div className="sidebar__headerAvatar">
                <Avatar alt={user?.full_name} src={user?.profileUrl} />
              </div>
            </MenuItem>
            <MenuItem>
              <div className="sidebar__headerMenu">
                <p> {`Mobile - ${mobile}`}</p>
              </div>
            </MenuItem>
            <MenuItem onClick={this.handleLogout}>
              <div className="sidebar__headerMenu">
                <PowerSettingsNewIcon />
                <p className="sidebar__headerLogout">Logout</p>
              </div>
            </MenuItem>
          </Menu>
        </div>
        <GroupSearch
          searchGroup={this.filterGroups}
          searchBy={this.state.searchBy}
          handleSearchByChange={this.handleSearchByChange}
        />
        <div className="sidebar__chat">
          {this.state.filteredList.length > 0
            ? this.renderFilteredList()
            : this.renderList()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
});

export default connect(mapStateToProps, { logout })(GroupList);
