import {
  FEED_LOADING_STARTED,
  UPDATED_FEED,
  UPDATED_FEED_ON_LOAD_MORE,
  SET_SELECTED_FEED,
  POST_MESSAGE_SUCCESS,
  POST_INLINE_MESSAGE_SUCCESS,
  POST_MESSAGE_ERROR,
  THREAD_REPLY_MESSAGE_SUCCESS
} from "../constants/types";

const initialState = {
  feed: [],
  loadingFeed: false,
  selectedFeed: null,
  page: 0,
  postSuccess: null,
  postError: null,
  isReply: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FEED_LOADING_STARTED:
      return {
        ...state,
        loadingFeed: true,
      };
    case UPDATED_FEED:
      return {
        ...state,
        feed: action.feed,
        session_id: action.session_id,
        hasMore: action.hasMore,
        page: action.page,
        loadingFeed: false,
        fetchedMore: false,
        fetchedFirstLoad: action.fetchedFirstLoad,
      };
    case UPDATED_FEED_ON_LOAD_MORE:
      return {
        ...state,
        feed: [...action.feed, ...state.feed],
        session_id: action.session_id,
        scrollTo: action.scrollTo,
        hasMore: action.hasMore,
        page: action.page,
        loadingFeed: false,
        fetchedMore: true,
        fetchedFirstLoad: false,
        isReply: false
      };
    case SET_SELECTED_FEED:
      return {
        ...state,
        fetchedFirstLoad: true,
        selectedFeed: payload,
      };
    case POST_MESSAGE_SUCCESS:
      return {
        ...state,
        isPostSuccess: payload,
      };
    case POST_INLINE_MESSAGE_SUCCESS:
      return {
        ...state,
        postSuccess: payload,
        fetchedFirstLoad: false,
        isReply: true
      };
    case POST_MESSAGE_ERROR:
      return {
        ...state,
        postError: payload,
      };
    case THREAD_REPLY_MESSAGE_SUCCESS:
      return {
        ...state,
        isReply: true,
        fetchedFirstLoad: false,
      };
    default:
      return state;
  }
};
