import firebase from "firebase/app";
import firebaseForRemoteConfig from "firebase";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCG4UsgeDUnpK_Ye8Z4-7pUz6JUi061978",
  authDomain: "apnatime-fbc72.firebaseapp.com",
  databaseURL: "https://apnatime-fbc72.firebaseio.com",
  projectId: "apnatime-fbc72",
  storageBucket: "mumbai_apnatime_prod",
  messagingSenderId: "978462882991",
  appId: "1:978462882991:web:cd89bdc453386938",
};

firebase.initializeApp(firebaseConfig);
const remoteConfig = firebaseForRemoteConfig.remoteConfig();

remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000,
};

remoteConfig.defaultConfig = {
  clap_levels: {
    clap_levels: [
      { level: "starter", value: 0 },
      { level: "silver", value: 100 },
      { level: "gold", value: 1000 },
      { level: "diamond", value: 10000 },
      { level: "influencer", value: 15000 },
    ],
  },
};

remoteConfig
  .ensureInitialized()
  .then(() => {
    console.log("Firebase Remote Config is initialized");
  })
  .catch((err) => {
    console.error("Firebase Remote Config failed to initialize", err);
  });

export const storage = firebase.storage().ref();

export const remote = remoteConfig;
