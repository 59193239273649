import {
  CLOSE_THREAD_PANEL,
  OPEN_THREAD_PANEL,
  FETCHED_REPLIES,
  THREAD_REPLY_UPDATE,
} from "../constants/types";

const initialState = {
  post: null,
  replyList: [],
  threadOpen: false,
  page: 1,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case OPEN_THREAD_PANEL:
      return {
        ...state,
        threadOpen: true,
        post: payload,
        replyList: [],
        page: 1,
        prevCount: null,
      };
    case CLOSE_THREAD_PANEL:
      return {
        ...state,
        threadOpen: false,
        post: null,
        page: 1,
        prevCount: null,
        replyList: [],
      };
    case FETCHED_REPLIES:
      return {
        ...state,
        replyList: [...payload, ...state.replyList],
        page: action.page,
        prevCount: action.prevCount,
      };
    case THREAD_REPLY_UPDATE:
      return {
        ...state,
        replyList: [...state.replyList, payload],
      };
    default:
      return state;
  }
};
