import {
  UPDATE_NOTIFICATIONS,
  SELECTED_NOTIFICATION,
  NOTIFICATION_COUNT_DECREASE,
} from "../constants/types";

const initialState = {
  notificationList: [],
  newCount: 0,
  selectedNotification: {},
};

export default (state = initialState, action) => {
  const { type, payload, newCount } = action;
  switch (type) {
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notificationList: payload,
        newCount: newCount,
      };
    case SELECTED_NOTIFICATION:
      return {
        ...state,
        selectedNotification: payload,
      };
    case NOTIFICATION_COUNT_DECREASE:
      return {
        ...state,
        newCount: state.newCount - 1 >= 0 ? state.newCount - 1 : 0,
      };
    default:
      return state;
  }
};
