import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import classNames from "classnames";
import GroupList from "../groups/GroupList";
import Feed from "../feed/Feed";
import "./MainLayout.css";
import { Get } from "../../utils/api";
import APIConfig from "../../utils/APIConfig";
import {
  updateGroupList,
  initLastSeenAt,
} from "../../actions/update-groups-list.action";
import { setThreadOpen } from "../../actions/create-new-post.action";
import {
  setGroupsMobileOpen,
  setGroupsMobileClose,
} from "../../actions/auth.action";
import { fetchNotifications } from "../../actions/notification-action";
import { isMobile } from "../../utils/formatDate";

const groupDrawerWidth = 340;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: groupDrawerWidth,
      flexShrink: 0,
    },
  },
  mainContentShift: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${groupDrawerWidth}px)`,
      marginLeft: groupDrawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: groupDrawerWidth,
  },
  content: {
    flexGrow: 1,
  },
});

class MainLayout extends Component {
  componentDidMount() {
    this.fetchGroupDetails();
    if (isMobile()) {
      this.props.setGroupsMobileOpen();
    }
  }

  fetchPostByPostId = (postId) => {
    if (postId.length > 4) {
      Get(`${APIConfig.GET_POST_BY_POSTID}${postId}`).then((response) => {
        let postDetails = response && Array.isArray(response) && response[0];
          this.props.setThreadOpen({
          ...postDetails,
          postIndex: 0,
        });
      });
    }
  };

  fetchGroupDetails = () => {
    this.groupdetailsSetInterval(this.groupdetails, 10000);
  };
  groupdetailsSetInterval = (func, interval) => {
    const { updateGroupList, initLastSeenAt, fetchNotifications } = this.props;
    Get(APIConfig.GROUP_BASE).then((response) => {
      updateGroupList(response?.groups);
      initLastSeenAt(response?.groups);
      fetchNotifications();
    });
    return setInterval(func, interval);
  };

  groupdetails = () => {
    Get(APIConfig.GROUP_BASE).then((response) => {
      this.props.updateGroupList(response?.groups);
    });
    this.props.fetchNotifications();
  };

  render() {
    const { classes } = this.props;
    const groupMobileOpen = this.props.auth?.groupMobileOpen;

    return (
      <div className={`dashboard ${classNames(classes.root)}`}>
        <nav className={classes.drawer} aria-label="gropus list">
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={groupMobileOpen}
              onClose={this.props.setGroupsMobileClose}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <GroupList fetchPostByPostId={this.fetchPostByPostId} />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <GroupList fetchPostByPostId={this.fetchPostByPostId} />
            </Drawer>
          </Hidden>
        </nav>
        <div
          className={`feed_container ${classNames(classes.content, {
            [classes.mainContentShift]: groupMobileOpen,
          })}`}
        >
          <Feed />
        </div>
      </div>
    );
  }
}

const MainLayoutContainer = withStyles(styles, { withTheme: true })(MainLayout);

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  updateGroupList,
  initLastSeenAt,
  setGroupsMobileOpen,
  setGroupsMobileClose,
  fetchNotifications,
  setThreadOpen,
})(MainLayoutContainer);
