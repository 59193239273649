import { Get, OpenPost } from "../utils/api";
import history from "../utils/history";
import { storage } from "../firebase";
import { AddDataToLocalStorage } from "../utils/LocalStorage";
import APIConfig from "../utils/APIConfig";
import {
  LOGIN_SUCCESS,
  LOGOUT,
  FETCHED_USER_DETAILS,
  FETCH_USER_PROFILE_IMAGE,
  SELECTED_SEED_MEMBER,
  UPDATE_SEED_PROFILE_URL,
  SET_GROUP_PANEL_OPEN,
  SET_GROUP_PANEL_CLOSE,
} from "../constants/types";

// Login User
export const login = (name, mobile, otp) => (dispatch) => {
  const data = {
    phone_number: mobile,
    otp: otp,
    platform: "web",
    phone_number_prefixed: `91${mobile}`,
  };
  OpenPost(APIConfig.LOGIN, data)
    .then((res) => {
      if (res?.STATUS === "SUCCESS") {
        AddDataToLocalStorage("userToken", res.key);
        AddDataToLocalStorage("senderName", name);
        AddDataToLocalStorage("mobile", mobile);
        AddDataToLocalStorage("isAuthenticated", true);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res,
        });
        dispatch(loadUser());
        history.push("/feed");
      } else {
        localStorage.clear();
        window.location.reload();
      }
    })
    .catch((error) => console.error(error));
};

// Logout
export const logout = () => ({ type: LOGOUT });

// Load User
export const loadUser = () => (dispatch) => {
  Get(APIConfig.USER_PROFILE)
    .then((res) => {
      dispatch({
        type: FETCHED_USER_DETAILS,
        payload: res,
      });
      AddDataToLocalStorage("user", res);
      dispatch(changeUserProfileImage(res));
      dispatch(setSelectedSeedUser(res));
    })
    .catch((error) => console.error(error));
};

export const changeUserProfileImage = (userData) => (dispatch) => {
  storage
    .child(`${userData.photo_firebase_path}`)
    .getDownloadURL()
    .then((url) => {
      dispatch({
        type: FETCH_USER_PROFILE_IMAGE,
        payload: url,
      });
      dispatch({
        type: UPDATE_SEED_PROFILE_URL,
        payload: url,
      });
    })
    .catch((error) => console.error(error));
};

export const setSelectedSeedUser = (user) => (dispatch) => {
  dispatch({
    type: SELECTED_SEED_MEMBER,
    payload: user,
  });
};

export const setGroupsMobileOpen = () => (dispatch) => {
  dispatch({
    type: SET_GROUP_PANEL_OPEN,
    payload: true,
  });
};
export const setGroupsMobileClose = () => (dispatch) => {
  dispatch({
    type: SET_GROUP_PANEL_CLOSE,
    payload: true,
  });
};
