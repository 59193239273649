import React from "react";
import Popover from "@material-ui/core/Popover";
import Tag from "../tag/Tag";

export default function MediaTagSuggestions({
  filteredList,
  setTaggedMember,
  handlePopoverClose,
  showFeedTagSuggestions,
  inputEl,
}) {
  const selectTaggedMember = (tag) => {
    setTaggedMember(tag);
    handlePopoverClose();
  };
  return (
    <Popover
      id=""
      open={showFeedTagSuggestions}
      onClose={handlePopoverClose}
      anchorEl={inputEl.current}
      anchorOrigin={{
        vertical: "top",
        horizontal: 450,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      disableAutoFocus
      disableEnforceFocus
      PaperProps={{
        style: { maxHeight: 400 },
      }}
    >
      {filteredList &&
        filteredList.map((tag) => (
          <Tag key={tag?.id} setTaggedMember={selectTaggedMember} tag={tag} />
        ))}
    </Popover>
  );
}
