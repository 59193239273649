export default {
  BASE_PATH: `${process.env.REACT_APP_BASE_URL}/api/community/`,
  GROUP_BASE: "v4/groups/",
  GROUP_NOTIFICATION: `${process.env.REACT_APP_BASE_URL}/api/community/v3/groups/unread-count/`,
  NOTIFICATIONS: `${process.env.REACT_APP_BASE_URL}/api/notification/v3/user-notifications/`,
  GROUP_FEED: `${process.env.REACT_APP_BASE_URL}/api/community/v8/groups/`,
  USER_PROFILE: `${process.env.REACT_APP_BASE_URL}/api/userprofile/v1/user/`,
  GET_OTP: `${process.env.REACT_APP_BASE_URL}/api/userprofile/v1/otp/`,
  LOGIN: `${process.env.REACT_APP_BASE_URL}/apna-auth/otp/login/`,
  MCK_FILE_URL: "https://applozic.appspot.com",
  FILE_PREVIEW_URL: "/rest/ws/aws/file/",
  FOR_SQL_QUERY: `${process.env.REACT_APP_BASE_URL}/api/v1/sql-query/`,
  MIX_PANEL_KEY: "b96e1914cc08adb5d3332330e947ca74",
  ENABLER_FEEDBACK: `${process.env.REACT_APP_BASE_URL}/api/retarget/v1/send-enabler-feedback`,
  SELECTED_USER_INFO: `${process.env.REACT_APP_BASE_URL}/api/retarget/v1/dashboard-user-detail`,
  GROUP_FEED_POST: `${process.env.REACT_APP_BASE_URL}/api/community/v4/groups/`,
  NOTIFICATION_READ_STATUS: `${process.env.REACT_APP_BASE_URL}/api/notification/v1/read_status`,
  TAGGING_PATH: `${process.env.REACT_APP_BASE_URL}/user-search/api/v1/user/`,
  REPORT_USER: `${process.env.REACT_APP_BASE_URL}/api/userprofile/v1/user/`,
  GET_POST_BY_POSTID: `${process.env.REACT_APP_BASE_URL}/api/community/v2/post_detail/`,
};
