import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ReactAudioPlayer from "react-audio-player";
import MicrolinkCard from "@microlink/react";
import { setThreadClose } from "../../actions/create-new-post.action";
import { ReactComponent as ClapIcon } from "../../assets/PostClap.svg";
import { ReactComponent as ReportIcon } from "../../assets/Report.svg";
import { ReactComponent as FileIcon } from "../../assets/File.svg";
import { ReactComponent as ClappedIcon } from "../../assets/Clapped.svg";
import { dateFormat, convertToMBs } from "../../utils/formatDate";
import useFirebaseFileLoader from "../../utils/FirebaseFileLoader";
import FeedResponse from "../feedResponse/FeedResponse";
import ThreadReply from "./ReplyComponent";
import Linkify from "react-linkify";
import ReactHtmlParser from "react-html-parser";
import { fetchUserDetails } from "../../actions/user-details.action";
import {
  fetchClappersList,
  fetchRepliesOnPost,
} from "../../actions/create-new-post.action";
import { updateClapCount } from "../../actions/feed-clap-count.action";
import { closeThreadTagSuggestions } from "../../actions/update-tag-suggestion.action";
import ReportOptions from "../reportOptions/ReportOptions";
import ReportMessage from "../reportMessage/ReportMessage";
import SuccessToast from "../dashboard/SuccessToast";
import Badge from "../badge/Badge";
import MediaModal from "../mediaModal/MediaModal";
import APIConfig from "../../utils/APIConfig";
import { Get, Post } from "../../utils/api";
import "./Thread.css";
import Mixpanel from "../../utils/Mixpanel";
import { GetCurrentStorage } from "../../utils/LocalStorage";

const loggedInUser = GetCurrentStorage("user");

export default function Thread() {
  const dispatch = useDispatch();
  const thread = useSelector((state) => state.thread);
  const replyCountList = useSelector((state) => state.replyCountList);
  const clapCountList = useSelector((state) => state.clapCountList);
  const seedUsers = useSelector((state) => state.seedUsers);
  const selectedSeedUser = seedUsers?.selectedSeedUser;
  const post = thread?.post;
  const postUrlPath = post?.data?.url;
  const [postUrl, loading] = useFirebaseFileLoader(postUrlPath);
  const replyList = thread?.replyList;
  const groups = useSelector((state) => state.groups);
  const groupList = groups?.groupList;
  const user = post?.user;
  const location = user?.profile?.city_obj?.name || "";
  const postDetail = post?.data;
  const taggedUsers = postDetail?.taggedMembersList;
  const postText = postDetail?.caption || postDetail?.text || "";
  const urlMatches =
    (postText && postText.match(/\b(http|https)?:\/\/\S+/gi)) || [];
  const workDetail = user?.work_info;
  const [clapDetail, setClapDetail] = useState({});
  const isClappedNow = clapDetail?.post_id === post?.id || false;
  const [toastOpen, setToastOpen] = useState(false);
  const [openMedia, setOpenMedia] = useState(false);
  const [postAvatar, imgLoading] = useFirebaseFileLoader(
    user?.photo_firebase_path
  );
  const [openReportOptions, setOpenReportOptions] = useState(false);
  const [openReportMessage, setOpenReportMessage] = useState(false);
  const [selectedReportOption, setSelectedReportOption] = useState(false);
  const [messageBody, setMessageBody] = useState("");

  const postUserId = user?.id;
  const seedUserId = selectedSeedUser?.id;

  const isPostedByCurrentUser = useMemo(
    () => String(seedUserId) === String(postUserId),
    [postUserId, seedUserId]
  );

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const closeThread = () => {
    dispatch(setThreadClose());
    dispatch(closeThreadTagSuggestions());
  };
  const findGroupName = (id) => {
    let group;
    if (groupList && groupList.length > 0) {
      group = groupList.find((group) => group.id === id);
      return group;
    }
    return null;
  };
  const selectedGroup = findGroupName(post?.group);
  const renderPostText = (taggedUsers = [], message) => {
    let newMessage = message;
    let shiftBy = 0;
    if (taggedUsers.length > 0) {
      taggedUsers.forEach((membersDetails) => {
        let taggedUser =
          message &&
          message.substring(membersDetails.start, membersDetails.end + 1);
        newMessage =
          newMessage.substring(0, membersDetails.start + shiftBy) +
          '<span class="taggedUser">' +
          taggedUser +
          "</span>" +
          newMessage.substring(membersDetails.end + 1 + shiftBy);
        shiftBy = shiftBy + '<span class="taggedUser"></span>'.length;
      });
      return newMessage;
    } else {
      return message;
    }
  };
  const fetchUserInformation = () => {
    dispatch(fetchUserDetails(user?.id));
  };
  const openClapsList = () => {
    dispatch(fetchClappersList(post?.id));
    Mixpanel.track("CHAT_CLAPPERS_CLICK", {
      GROUP_ID: post?.group,
      GROUP_NAME: selectedGroup?.name,
      SENDER: "web",
      POSITION: post?.postIndex,
    });
  };
  const onClapPost = () => {
    const postId = post?.id;
    const userId = selectedSeedUser?.id;
    let data = {
      count: 1,
      user_id: userId,
    };
    Post(APIConfig.BASE_PATH + `v1/claps/${postId}?is_ops_activity=true`, data)
      .then((res) => {
        if (res?.post_id === postId) {
          setClapDetail(res);
          Get(APIConfig.BASE_PATH + `v1/claps/${postId}/users`)
            .then((response) => {
              dispatch(updateClapCount(postId, response?.clap_count));
              Mixpanel.track("CHAT_CLAP_CLICK", {
                GROUP_ID: post?.group,
                GROUP_NAME: selectedGroup?.name,
                SENDER: "web",
                POST_ID: postId,
                POSITION: post?.postIndex,
              });
            })
            .catch((error) => console.error(error));
        }
      })
      .catch((error) => console.error(error));
  };
  const handleReplies = () => {
    dispatch(fetchRepliesOnPost(post?.id));
  };
  const openMediaModal = () => {
    setOpenMedia(true);
  };
  const handleMediaClose = () => {
    setOpenMedia(false);
  };

  const handleReportClick = () => {
    setOpenReportOptions(true);
    setMessageBody("");
    setSelectedReportOption({});
    Mixpanel.track("REPORT_POST_CLICKED", {
      SOURCE: "1:1, Profile",
      REPORTED_USER_ID: post?.user?.id,
      USER_ID: loggedInUser?.id,
      POST_ID: post?.id,
      GROUP_ID: post?.group,
      TYPE: "OM",
      SENDER: "web",
    });
  };

  const closeReportOptionsModal = () => {
    setOpenReportOptions(false);
  };

  const openReportMessageModal = () => {
    setOpenReportMessage(true);
  };

  const closeReportMessageModal = () => {
    setOpenReportMessage(false);
    setOpenReportOptions(false);
    setMessageBody("");
    setSelectedReportOption({});
  };

  const handleBackToOptionsModal = () => {
    setMessageBody("");
    setOpenReportMessage(false);
    setOpenReportOptions(true);
  };

  const handleReportPostSubmitClick = () => {
    let data = {
      report_type: selectedReportOption?.value,
      message_info: {
        body: messageBody || "",
      },
    };
    const postId = post?.id;
    const userId = post?.user?.id || "";
    Post(APIConfig.BASE_PATH + `v1/post/report/${postId}`, data)
      .then((res) => {
        if (res) {
          Mixpanel.track("REPORT_POST_SUBMITTED", {
            SOURCE: "1:1, Profile",
            AUTHOR_ID: userId,
            OPTION: selectedReportOption?.title,
            FEEDBACK_TEXT: messageBody,
            USER_ID: loggedInUser?.id,
            TYPE: "OM",
            SENDER: "web",
          });
          setTimeout(() => {
            closeReportMessageModal();
            setToastOpen(true);
          }, 1000);
        }
      })
      .catch((error) => {
        closeReportMessageModal();
        console.error(error);
      });
  };

  return (
    <>
      <div className="thread__header">
        <div className="thread__headerInfo">
          <span>Post</span>
          <span>{selectedGroup?.name}</span>
        </div>
        <IconButton onClick={closeThread}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="thread__content">
        <div className="post__header">
          <Avatar
            className="post__avatar"
            alt={user?.full_name}
            src={post?.avatarImage || postAvatar}
            onClick={() => fetchUserInformation()}
          />
          <div className="post__headerInfo">
            <div className="post__headerMeta">
              <h3>{`${user?.full_name} - ${user?.id}`}</h3>
              <Badge clapCount={user?.clap_count} />
            </div>
            {workDetail?.experience_level?.level === "e" && (
              <p>{`${
                workDetail?.company_title ? workDetail.company_title : "Fresher"
              } ${
                workDetail?.company
                  ? `at ${workDetail && workDetail.company}`
                  : ""
              }`}</p>
            )}
            {location && <p>{location}</p>}
            {workDetail?.experience_level?.level === "f" && <p>Fresher</p>}
          </div>
        </div>
        {postDetail?.type.toLowerCase() === "image" && postDetail.url && (
          <>
            {!postUrl && <div className="thread__imgContainer"></div>}
            {postUrl && (
              <img
                className="thread__image"
                src={postUrl}
                onClick={openMediaModal}
                alt=""
              />
            )}
          </>
        )}
        {postDetail?.type.toLowerCase() === "video" && postDetail.url && (
          <div onClick={openMediaModal}>
            <video
              className="thread__video"
              controls
              muted
              src={postUrl}
              type="video/mp4"
            ></video>
          </div>
        )}
        {postDetail &&
          postDetail.type.toLowerCase() === "file" &&
          postDetail.url && (
            <div className="post__file" onClick={openMediaModal}>
              <div className="post__fileIcon">
                <FileIcon />
              </div>
              <div className="post__fileInfo">
                <span>{postDetail.fileName || "unknown file"}</span>
                <span>{convertToMBs(postDetail.size)}</span>
              </div>
            </div>
          )}
        {postDetail && postDetail.type.toLowerCase() === "audio" && (
          <div className="post__audio">
            <ReactAudioPlayer src={postUrl} controls />
          </div>
        )}
        {postText && (
          <Linkify>
            <h4 className="post__text">
              {ReactHtmlParser(renderPostText(taggedUsers, postText))}
            </h4>
          </Linkify>
        )}
        <div className="post__links">
          {urlMatches &&
            urlMatches.map((link) => (
              <MicrolinkCard key={Math.random()} url={link} />
            ))}
        </div>
        <div className="post__info">
          <span onClick={openClapsList}>{`${clapCountList[post?.id] || 0} ${
            clapCountList[post?.id] > 1 ? "Claps" : "Clap"
          }`}</span>
          <span>{dateFormat(post?.created_at)}</span>
        </div>
        <div className="post__icons">
          <Button
            size="small"
            startIcon={
              post?.clapped || isClappedNow ? <ClappedIcon /> : <ClapIcon />
            }
            onClick={onClapPost}
          >
            Clap
          </Button>
          {!isPostedByCurrentUser && (
            <Button
              size="small"
              startIcon={<ReportIcon />}
              onClick={handleReportClick}
            >
              Report
            </Button>
          )}
        </div>
        {replyList && thread.prevCount !== null && thread.prevCount > 0 && (
          <div className="post__reply">
            <span onClick={handleReplies}>
              {thread.prevCount <= 1
                ? `view previous ${thread.prevCount} reply`
                : `view previous ${thread.prevCount} replies`}
            </span>
          </div>
        )}
        {replyList &&
          replyList.map((reply) => (
            <ThreadReply reply={reply} key={reply?.id} isThread />
          ))}
        <FeedResponse
          type="thread"
          selectedFeed={selectedGroup}
          repliedPost={post}
        />
        <ReportOptions
          isOpen={openReportOptions}
          onClose={closeReportOptionsModal}
          setReportOption={setSelectedReportOption}
          openReportMessageModal={openReportMessageModal}
          type="post"
        />
        <ReportMessage
          isOpen={openReportMessage}
          onClose={closeReportMessageModal}
          reportOption={selectedReportOption}
          messageBody={messageBody}
          setMessageBody={setMessageBody}
          handleReport={handleReportPostSubmitClick}
          handleBack={handleBackToOptionsModal}
        />
        <SuccessToast open={toastOpen} handleClose={handleToastClose} />
        <MediaModal
          isOpen={openMedia}
          onClose={handleMediaClose}
          type={postDetail?.type?.toLowerCase()}
          mediaFile={postUrl}
          post={{
            avatar: post?.avatarImage,
            name: user?.full_name,
            createdAt: dateFormat(post?.created_at),
          }}
        />
      </div>
    </>
  );
}
