import {
  INIT_CLAP_COUNT,
  UPDATE_POST_CLAP_COUNT,
  RESET_CLAP_COUNT,
} from "../constants/types";

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INIT_CLAP_COUNT:
      return {
        ...state,
        ...payload,
      };
    case RESET_CLAP_COUNT:
      return initialState;
    case UPDATE_POST_CLAP_COUNT:
      return {
        ...state,
        [parseInt(payload.postId)]:
          state[payload.postId] < parseInt(payload.count)
            ? parseInt(payload.count)
            : state[payload.postId],
      };
    default:
      return state;
  }
};
