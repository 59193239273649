import { combineReducers } from "redux";

import auth from "./authReducer";
import groups from "./groupReducer";
import feedList from "./feedReducer";
import thread from "./threadReducer";
import seedUsers from "./seedUserReducer";
import clapList from "./clapReducer";
import userInfo from "./userInfoReducer";
import replyCountList from "./replyCountReducer";
import clapCountList from "./clapCountReducer";
import tags from "./tagReducer";
import notifications from "./notificationReducer";

export default combineReducers({
  auth,
  groups,
  feedList,
  thread,
  seedUsers,
  clapList,
  userInfo,
  replyCountList,
  clapCountList,
  tags,
  notifications,
});
