import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { closeClappersList } from "../../actions/create-new-post.action";
import { Avatar } from "@material-ui/core";
import { ReactComponent as ClapIcon } from "../../assets/PostClap.svg";
import useFirebaseFileLoader from "../../utils/FirebaseFileLoader";
import "./ClappersList.css";
import Spinner from "../dashboard/Spinner";
import { isMobile } from "../../utils/formatDate";

const modalWidth = isMobile() ? "100%" : 400;

function getModalStyle() {
  const top = 0;
  const right = 0;

  return {
    top: `${top}%`,
    right: `${right}%`,
    outline: "none",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    position: "absolute",
    width: modalWidth,
    height: "100vh",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
}));

const ClapperInfo = ({ clapper }) => {
  const profileUrlPath = clapper?.photo_firebase_path;
  const [profileUrl, loading] = useFirebaseFileLoader(profileUrlPath);
  const companyTitle = clapper?.work_info?.company_title;
  const company = clapper?.work_info?.company;
  return (
    <>
      <div className="clapper">
        <Avatar src={profileUrl} />
        <div className="clap__details">
          <span className="clap__name">{clapper.full_name}</span>
          {companyTitle || company ? (
            <>
              <span>{companyTitle}</span>
              {companyTitle && <span>{company}</span>}
            </>
          ) : (
            <span>Fresher</span>
          )}
        </div>
        <span>{`+ ${clapper?.clap_count} claps`}</span>
      </div>
      <hr />
    </>
  );
};

const ClappersList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const clapList = useSelector((state) => state.clapList);
  const handleClose = () => {
    dispatch(closeClappersList());
  };

  return (
    <Modal open={clapList?.isOpen} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className="clap__header">
          <div className="clap__headerIcons">
            <span>
              <ClapIcon />
            </span>
            <span>{clapList?.totalClaps}</span>
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="clap__content">
          {clapList?.loading && <Spinner />}
          {!clapList?.loading &&
            clapList?.clappers &&
            clapList?.clappers.map((user, index) => (
              <ClapperInfo clapper={user} key={index} />
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default ClappersList;
