import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, IconButton, Menu, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactAudioPlayer from "react-audio-player";
import { ReactComponent as ClapIcon } from "../../assets/PostClap.svg";
import { ReactComponent as ClappedIcon } from "../../assets/Clapped.svg";
import { ReactComponent as FileIcon } from "../../assets/File.svg";
import { dateFormat, convertToMBs } from "../../utils/formatDate";
import useFirebaseFileLoader from "../../utils/FirebaseFileLoader";
import Linkify from "react-linkify";
import ReactHtmlParser from "react-html-parser";
import { fetchUserDetails } from "../../actions/user-details.action";
import { Post, Get } from "../../utils/api";
import APIConfig from "../../utils/APIConfig";
import ReportOptions from "../reportOptions/ReportOptions";
import ReportMessage from "../reportMessage/ReportMessage";
import SuccessToast from "../dashboard/SuccessToast";
import Badge from "../badge/Badge";
import MediaModal from "../mediaModal/MediaModal";
import Mixpanel from "../../utils/Mixpanel";
import { GetCurrentStorage } from "../../utils/LocalStorage";
import { fetchClappersList } from "../../actions/create-new-post.action";

const loggedInUser = GetCurrentStorage("user");

export default function ReplyComponent({ reply, isThread }) {
  const dispatch = useDispatch();
  const { selectedSeedUser } = useSelector((state) => state.seedUsers);
  const groups = useSelector((state) => state.groups);
  const groupList = groups?.groupList;
  const [showReplyInfo, setShowReplyInfo] = useState(false);
  const replyDetail = reply?.data;
  const replyUrlPath = reply?.data?.url;
  const [replyUrl, loading] = useFirebaseFileLoader(replyUrlPath);
  const replyProfileUrlPath = reply?.user?.photo_firebase_path;
  const [replyProfileUrl, loadingProfile] = useFirebaseFileLoader(
    replyProfileUrlPath
  );
  const taggedUsers = replyDetail?.taggedMembersList;
  const replyText = reply?.data?.caption || reply?.data?.text || "";
  const [clapDetail, setClapDetail] = useState({});
  const [fetchedClappedDetail, setFetchedClappedDetail] = useState({});

  const isClappedNow = clapDetail?.post_id === reply?.id;
  const afterClappedClapCount =
    isClappedNow && fetchedClappedDetail?.clap_count;
  const clapCount =
    afterClappedClapCount && afterClappedClapCount > reply?.clap_count
      ? afterClappedClapCount
      : reply?.clap_count;

  const [anchorEl, setAnchorEl] = useState(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [openMedia, setOpenMedia] = useState(false);
  const [openReportOptions, setOpenReportOptions] = useState(false);
  const [openReportMessage, setOpenReportMessage] = useState(false);
  const [selectedReportOption, setSelectedReportOption] = useState(false);
  const [messageBody, setMessageBody] = useState("");

  const replyUserId = reply?.user?.id;
  const seedUserId = selectedSeedUser?.id;

  const isPostedByCurrentUser = useMemo(
    () => String(seedUserId) === String(replyUserId),
    [replyUserId, seedUserId]
  );

  const findGroupName = (id) => {
    let group;
    if (groupList && groupList.length > 0) {
      group = groupList.find((group) => group.id === id);
      return group;
    }
    return null;
  };
  const selectedGroup = findGroupName(reply?.group);

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderReply = (taggedUsers = [], reply) => {
    let newReply = reply;
    let shiftBy = 0;
    if (taggedUsers?.length > 0) {
      taggedUsers.forEach((membersDetails) => {
        let taggedUser =
          reply &&
          reply.substring(membersDetails.start, membersDetails.end + 1);
        newReply =
          newReply.substring(0, membersDetails.start + shiftBy) +
          '<span class="taggedUser">' +
          taggedUser +
          "</span>" +
          newReply.substring(membersDetails.end + 1 + shiftBy);
        shiftBy = shiftBy + '<span class="taggedUser"></span>'.length;
      });
      return newReply;
    } else {
      return reply;
    }
  };
  const toggleReplyInfo = () => {
    setShowReplyInfo(!showReplyInfo);
  };
  const fetchUserInformation = () => {
    dispatch(fetchUserDetails(reply?.user?.id));
  };
  const onClapPost = () => {
    const replyId = reply?.id;
    const userId = selectedSeedUser?.id;
    let data = {
      count: 1,
      user_id: userId,
    };
    Post(APIConfig.BASE_PATH + `v1/claps/${replyId}?is_ops_activity=true`, data)
      .then((res) => {
        if (res?.post_id === replyId) {
          setClapDetail(res);
          Get(APIConfig.BASE_PATH + `v1/claps/${replyId}/users`)
            .then((response) => {
              setFetchedClappedDetail(response);
              Mixpanel.track("CHAT_CLAP_CLICK", {
                GROUP_ID: reply?.group,
                GROUP_NAME: selectedGroup?.name,
                SENDER: "web",
                POST_ID: replyId,
              });
            })
            .catch((error) => console.error(error));
        }
      })
      .catch((error) => console.error(error));
  };
  const openClapsList = () => {
    dispatch(fetchClappersList(reply?.id));
    Mixpanel.track("CHAT_CLAPPERS_CLICK", {
      GROUP_ID: reply?.group,
      GROUP_NAME: selectedGroup?.name,
      SENDER: "web",
    });
  };
  const openMediaModal = () => {
    setOpenMedia(true);
  };
  const handleMediaClose = () => {
    setOpenMedia(false);
  };

  const handleReportClick = () => {
    setOpenReportOptions(true);
    setMessageBody("");
    setSelectedReportOption({});
    Mixpanel.track("REPORT_POST_CLICKED", {
      SOURCE: "1:1, Profile",
      REPORTED_USER_ID: reply?.user?.id,
      USER_ID: loggedInUser?.id,
      POST_ID: reply?.id,
      GROUP_ID: reply?.group,
      TYPE: "EM",
      SENDER: "web",
    });
  };
  const closeReportOptionsModal = () => {
    setOpenReportOptions(false);
    handleClose();
  };

  const openReportMessageModal = () => {
    setOpenReportMessage(true);
  };

  const closeReportMessageModal = () => {
    setOpenReportMessage(false);
    setOpenReportOptions(false);
    setMessageBody("");
    setSelectedReportOption({});
    handleClose();
  };

  const handleBackToOptionsModal = () => {
    setMessageBody("");
    setOpenReportMessage(false);
    setOpenReportOptions(true);
  };
  const handleReportPostSubmitClick = () => {
    let data = {
      report_type: selectedReportOption?.value,
      message_info: {
        body: messageBody || "",
      },
    };
    const replyId = reply?.id;
    const userId = replyId?.user?.id || "";
    Post(APIConfig.BASE_PATH + `v1/post/report/${replyId}`, data)
      .then((res) => {
        if (res) {
          Mixpanel.track("REPORT_POST_SUBMITTED", {
            SOURCE: "1:1, Profile",
            AUTHOR_ID: userId,
            OPTION: selectedReportOption?.title,
            FEEDBACK_TEXT: messageBody,
            USER_ID: loggedInUser?.id,
            TYPE: "EM",
            SENDER: "web",
          });
          setTimeout(() => {
            closeReportMessageModal();
            setToastOpen(true);
          }, 1000);
        }
      })
      .catch((error) => {
        closeReportMessageModal();
        console.error(error);
      });
  };

  return (
    <div className="threadReply">
      <div className="post__comments">
        <Avatar
          className="post__avatar"
          alt={reply?.user?.full_name}
          src={replyProfileUrl}
          onClick={() => fetchUserInformation()}
        />
        <div className="thread__commentsInfo">
          {showReplyInfo && (
            <div className="reply__meta">
              <span>{`Reply ID - ${reply?.id}`}</span>
            </div>
          )}
          <span
            onClick={toggleReplyInfo}
          >{`${reply?.user?.full_name} - ${reply?.user?.id}`}</span>
        </div>
        <p className="post__commentsRight">
          <span>{dateFormat(reply?.created_at)}</span>
          {!isPostedByCurrentUser && (
            <IconButton onClick={handleClick}>
              <ExpandMoreIcon />
            </IconButton>
          )}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleReportClick}>Report</MenuItem>
          </Menu>
        </p>
      </div>
      <div className="reply__badge">
        <Badge clapCount={reply?.user?.clap_count} />
      </div>
      <div className="threadReply_content">
        {replyDetail?.type.toLowerCase() === "image" && replyDetail?.url && (
          <>
            {!replyUrl && <div className="threadReply__imgContainer"></div>}
            {replyUrl && (
              <img
                className="threadReply__image"
                src={replyUrl}
                onClick={openMediaModal}
                alt=""
              />
            )}
          </>
        )}
        {replyDetail?.type.toLowerCase() === "video" && replyDetail?.url && (
          <div onClick={openMediaModal}>
            <video
              className="threadReply__video"
              controls
              muted
              src={replyUrl}
              type="video/mp4"
            ></video>
          </div>
        )}
        {replyDetail?.type.toLowerCase() === "file" && replyDetail?.url && (
          <div className="threadReply__file" onClick={openMediaModal}>
            <div className="threadReply__fileIcon">
              <FileIcon />
            </div>
            <div className="threadReply__fileInfo">
              <span>{replyDetail?.fileName}</span>
              <span>{convertToMBs(replyDetail?.size)}</span>
            </div>
          </div>
        )}
        {replyDetail?.type.toLowerCase() === "audio" && (
          <div className="threadReply__audio">
            <ReactAudioPlayer src={replyUrl} controls />
          </div>
        )}
        {replyText && (
          <Linkify>
            <span className="reply__text">
              {ReactHtmlParser(renderReply(taggedUsers, replyText))}
            </span>
          </Linkify>
        )}
      </div>
      {isThread && (
        <div className="threadReply_clap">
          <IconButton onClick={onClapPost}>
            {replyDetail?.clapped || isClappedNow ? (
              <ClappedIcon />
            ) : (
              <ClapIcon />
            )}
          </IconButton>
          {clapCount > 0 && (
            <span
              onClick={openClapsList}
              className="threadReply_clapCount"
            >{`${clapCount || 0} ${clapCount > 1 ? "Claps" : "Clap"}`}</span>
          )}
        </div>
      )}
      <ReportOptions
        isOpen={openReportOptions}
        onClose={closeReportOptionsModal}
        setReportOption={setSelectedReportOption}
        openReportMessageModal={openReportMessageModal}
        type="reply"
      />
      <ReportMessage
        isOpen={openReportMessage}
        onClose={closeReportMessageModal}
        reportOption={selectedReportOption}
        messageBody={messageBody}
        setMessageBody={setMessageBody}
        handleReport={handleReportPostSubmitClick}
        handleBack={handleBackToOptionsModal}
      />

      <SuccessToast open={toastOpen} handleClose={handleToastClose} />
      <MediaModal
        isOpen={openMedia}
        onClose={handleMediaClose}
        type={replyDetail?.type.toLowerCase()}
        mediaFile={replyUrl}
        post={{
          avatar: replyProfileUrl,
          name: reply?.user?.full_name,
          createdAt: dateFormat(reply?.created_at),
        }}
      />
    </div>
  );
}
