import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { ReactComponent as BackIcon } from "../../assets/Back.svg";
import { Button } from "@material-ui/core";
import "./ReportMessage.css";
import { isMobile } from "../../utils/formatDate";

const modalWidth = isMobile() ? "90%" : 450;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: "none",
    borderRadius: "4px",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: modalWidth,
    height: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    width: "85%",
    background: "#2bb793",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 35,
    padding: "0 15px",
    marginTop: "10px",
    marginLeft: "3px",
    marginRight: "3px",
    outline: "none",
    "&:hover": {
      backgroundColor: "#28A886",
      boxShadow: "none",
    },
  },
  default: {
    width: "85%",
    background: "#a5a5a5",
    borderRadius: 3,
    border: 0,
    color: "black",
    height: 35,
    padding: "0 15px",
    marginLeft: "3px",
    marginRight: "3px",
    outline: "none",
    "&:hover": {
      backgroundColor: "#e1e1e1",
      boxShadow: "none",
    },
  },
  label: {
    fontWeight: 600,
    color: "white",
  },
  defaultLabel: {
    fontWeight: 600,
    color: "black",
  },
  textInput: {
    width: "95%",
    height: 150,
    marginBottom: 30,
    outline: "none",
  },
  reportMessage: {
    width: 430,
    height: 60,
    marginBottom: 15,
    outline: "none",
  },
}));

const ReportMessageStage = ({
  isOpen,
  onClose,
  reportOption,
  messageBody,
  setMessageBody,
  handleReport = () => {},
  handleBack = () => {},
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [maxLengthError, setMaxLengthError] = useState(false);

  const handleTextChange = (e) => {
    if (e.target.value?.trim().length <= 300) {
      setMaxLengthError(false);
    } else {
      setMaxLengthError(true);
    }
    setMessageBody(e.target.value);
  };
  const handleClose = () => {
    onClose();
    setMaxLengthError(false);
  };
  const handleGoBackToOptions = () => {
    handleBack();
    setMaxLengthError(false);
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="report-modal-title"
      aria-describedby="report-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div className="reportMessage">
          <div className="reportMessage__header">
            <BackIcon onClick={handleGoBackToOptions} />
            <div className="reportMessage__headerText">
              <b>Report {reportOption?.title}</b>
            </div>
          </div>
          <textarea
            className={classes.textInput}
            placeholder="Optional"
            autoFocus={true}
            value={messageBody}
            onChange={handleTextChange}
          />
          {maxLengthError && (
            <p className="reportMessage__infoText">
              * 300 is the maximum character limit for feedback.
            </p>
          )}
          <Button
            onClick={() => handleReport()}
            classes={{
              root: maxLengthError ? classes.default : classes.button,
              label: classes.label,
            }}
            disabled={maxLengthError}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReportMessageStage;
