import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, IconButton } from "@material-ui/core";
import { postMessage } from "../../actions/create-new-post.action";
import Popover from "@material-ui/core/Popover";
import MediaTagSuggestions from "./MediaTagSuggestions";
import Tag from "../tag/Tag";
import Spinner from "../dashboard/Spinner";
import CloseIcon from "@material-ui/icons/Close";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import Mixpanel from "../../utils/Mixpanel";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: "none",
    borderRadius: "8px",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    background: "#2bb793",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 50,
    padding: "0 15px",
    marginTop: "8px",
    marginLeft: "3px",
    marginRight: "3px",
    outline: "none",
    "&:hover": {
      backgroundColor: "#28A886",
      boxShadow: "none",
    },
  },
  label: {
    fontWeight: 600,
    color: "white",
  },
}));

const NewPostImageInput = ({
  isOpen,
  onClose,
  loading,
  url,
  mediaFile,
  handleFileInputChange,
  caption,
  handleCaptionChange,
  onCaptionEmojiClick,
  repliedPost,
  postType,
  fileSize,
  mediaFileName,
  tagSuggestionList,
  showTagSuggestions,
  taggedMembers,
  handlePopoverClose,
  handleKeyPress,
  setTaggedMember,
  showFeedTagSuggestions,
  filteredList,
}) => {
  const dispatch = useDispatch();
  const feedList = useSelector((state) => state.feedList);
  const selectedFeed = feedList?.selectedFeed;
  const { selectedSeedUser } = useSelector((state) => state.seedUsers);
  const inputEl = useRef(null);
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [emojiPanel, setEmojiPanel] = useState(false);

  const emojiPanelOpenClose = () => {
    setEmojiPanel(!emojiPanel);
  };

  const handleSend = (e) => {
    e.preventDefault();
    let tagList = [];
    let tagUsersList = [];
    taggedMembers.forEach((taggedMember) => {
      if (
        caption.substring(taggedMember.start, taggedMember.end + 1) ===
        `@${taggedMember.fullName}`
      ) {
        tagList.push({
          end: taggedMember.end,
          fullName: taggedMember.fullName,
          start: taggedMember.start,
          userId: taggedMember.userId,
        });
        tagUsersList.push(taggedMember.userId);
      } else if (caption.indexOf(taggedMember.fullName) > -1) {
        let start, end;
        start = caption.indexOf(`@${taggedMember.fullName}`);
        end = start + taggedMember.fullName.length;
        tagList.push({
          end: end,
          fullName: taggedMember.fullName,
          start: start,
          userId: taggedMember.userId,
        });
        tagUsersList.push(taggedMember.userId);
      }
    });
    let urlMatches = "";
    if (caption !== "" && caption.trim() !== "") {
      urlMatches = caption.match(/\b(http|https)?:\/\/\S+/gi);
    }
    let post = {};
    if (postType === "post") {
      post = {
        data: {
          isDeeplink: false,
          isRichLink: urlMatches ? true : false,
          taggedMembersList: tagList,
          caption: caption,
          source: "web",
          type: "IMAGE",
          url: `attachment/community/image/${mediaFileName}`,
          size: fileSize,
        },
        group: selectedFeed && selectedFeed.id,
        tagged_users: tagUsersList,
        type: "image",
        impersonate_user_id: selectedSeedUser?.id,
        send_notification: false,
      };
    }
    if (postType === "thread") {
      post = {
        data: {
          isDeeplink: false,
          isRichLink: urlMatches ? true : false,
          taggedMembersList: tagList,
          caption: caption,
          repliedPost: repliedPost,
          source: "web",
          type: "IMAGE",
          url: `attachment/community/image/${mediaFileName}`,
          size: fileSize,
        },
        group: selectedFeed?.id,
        replied_to: repliedPost?.id,
        tagged_users: tagUsersList,
        type: "image",
        impersonate_user_id: selectedSeedUser?.id,
        send_notification: false,
      };
    }
    dispatch(postMessage(post, selectedFeed?.id, postType));
    Mixpanel.track("CHAT_GROUP_IMAGE_SEND", {
      GROUP_ID: selectedFeed?.id,
      GROUP_NAME: selectedFeed?.name,
      SENDER: "web",
    });
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      disableBackdropClick
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div className="mediaupload__header">
          <span className="mediaupload__headerText">Upload an image</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="mediaupload" ref={inputEl}>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileInputChange(e)}
            disabled={loading}
          />

          <div className="mediaupload__file">
            {!loading && (
              <img
                className="threadReply__image"
                src={mediaFile !== null ? url : undefined}
                alt=""
              />
            )}
            {loading && (
              <div className="threadReply__imgContainer">
                <div className="mediaupload__spinner">
                  {loading && <Spinner />}
                </div>
              </div>
            )}
          </div>
          <div className="mediaupload__inputPanel">
            <textarea
              placeholder="type a message"
              value={caption}
              onChange={handleCaptionChange}
              onKeyDown={handleKeyPress}
            />
            <IconButton onClick={emojiPanelOpenClose}>
              <InsertEmoticonIcon />
            </IconButton>
            {emojiPanel && (
              <div className="message__footerEmoji">
                <Picker
                  onEmojiClick={onCaptionEmojiClick}
                  skinTone={SKIN_TONE_MEDIUM_DARK}
                />
              </div>
            )}
          </div>
          <Button
            disabled={!mediaFileName}
            variant="contained"
            onClick={(e) => handleSend(e)}
            classes={{
              root: classes.button,
              label: classes.label,
            }}
          >
            Send
          </Button>
        </div>
        {showFeedTagSuggestions && (
          <MediaTagSuggestions
            showFeedTagSuggestions={showFeedTagSuggestions}
            setTaggedMember={setTaggedMember}
            handlePopoverClose={handlePopoverClose}
            filteredList={filteredList}
            inputEl={inputEl}
          />
        )}
        <Popover
          id=""
          open={showTagSuggestions}
          onClose={handlePopoverClose}
          anchorEl={inputEl.current}
          anchorOrigin={{
            vertical: "top",
            horizontal: 450,
          }}
          disableAutoFocus
          disableEnforceFocus
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {tagSuggestionList &&
            tagSuggestionList.map((tag) => (
              <Tag key={tag?.id} setTaggedMember={setTaggedMember} tag={tag} />
            ))}
        </Popover>
      </div>
    </Modal>
  );
};

export default NewPostImageInput;
