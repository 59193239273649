import React, { Component } from "react";
import { connect } from "react-redux";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import { postMessage } from "../../actions/create-new-post.action";
import EditIcon from "@material-ui/icons/Edit";
import {
  fetchTagSuggestions,
  closeTagSuggestions,
  fetchTagSuggestionsNew,
} from "../../actions/update-tag-suggestion.action";
import MediaUploads from "../mediaUploads/MediaUploads";
import FeedTagSuggestions from "./FeedTagSuggestions";
import Tag from "../tag/Tag";
import { isMobile } from "../../utils/formatDate";
import Mixpanel from "../../utils/Mixpanel";
import "./FeedResponse.css";
import { storage } from "../../firebase";
import PasteToPreview from "../pasteToUpload";

class FeedResponse extends Component {
  state = {
    showFooter: true,
    message: "",
    isMention: false,
    showTagSuggestions: false,
    taggedMembers: [],
    open: false,
    emojiPanel: false,
    showFeedTagSuggestions: false,
    term: "",
    filteredList: [],
    blob: null,
    blobURL: "",
    caption: "",
  };
  componentDidMount() {
    let { tags, type } = this.props;
    const { feedTagSuggestions, threadTagSuggestions } = tags;
    let tagList = type === "thread" ? threadTagSuggestions : feedTagSuggestions;
    this.setState({ taggedMembers: [], filteredList: tagList });
  }
  inputRef = React.createRef();
  captionInputRef = React.createRef();
  setCaptionText = (captionText) => {
    this.setState({ caption: captionText });
  };
  onChange = async (e, isCaption = false) => {
    e.preventDefault();
    if (isCaption) {
      this.setCaptionText(e.target.value);
    } else {
      this.setMessageText(e.target.value);
    }
    const { isMention, taggedMembers } = this.state;
    const groupId =
      this.props.selectedFeed?.id || this.props.feedList?.selectedFeed?.id;
    let check = e.target.value.lastIndexOf("@");
    let trimValue = e.target.value.trim();
    if (check > -1) {
      let matchingData = e.target.value.substring(check + 1, trimValue.length);
      let existingTagIndex = taggedMembers.indexOf(matchingData);
      if (matchingData.length <= 2 && isMention) {
        this.setState({
          term: matchingData,
          showFeedTagSuggestions: true,
          showTagSuggestions: false,
        });
        this.filterTags(matchingData);
      }
      if (
        matchingData &&
        matchingData.length >= 0 &&
        isMention &&
        existingTagIndex === -1
      ) {
        let params = { groupId: groupId, keyword: matchingData, limit: 30 };
        if (
          this.props.type === "thread" &&
          this.props.repliedPost &&
          this.props.repliedPost.id
        ) {
          params.postId = this.props.repliedPost.id;
        }
        await this.props.fetchTagSuggestionsNew(
          params,
          this.props.selectedSeedUser?.id
        );
        this.setState({
          showTagSuggestions: true,
          term: "",
          showFeedTagSuggestions: false,
        });
      }
    } else {
      this.setState({
        showTagSuggestions: false,
        showFeedTagSuggestions: false,
      });
    }
  };
  onKeyDown = (e) => {
    let lastCharEntered =
      e.target.value.charAt(e.target.value.length - 1) || "";
    if (!isMobile() && e.key === "Enter" && !e.shiftKey) {
      this.sendMessage(e);
    }
    if (e.key === "@" || lastCharEntered === "@") {
      this.setState({
        isMention: true,
        term: "",
        showFeedTagSuggestions: true,
      });
    }
  };
  setMessageText = (messageText) => {
    this.setState({ message: messageText });
  };
  setTaggedMember = (taggedMember) => {
    let previousMessage = this.state.message;
    let lastIndex = previousMessage.lastIndexOf("@");
    this.setState({
      message:
        previousMessage.substring(0, lastIndex) +
        "@" +
        taggedMember.full_name +
        " ",
    });
    let start = lastIndex;
    let end = start + taggedMember.full_name.length;
    this.setState({
      taggedMembers: this.state.taggedMembers.concat([
        {
          start: start,
          end: end,
          fullName: taggedMember.full_name,
          userId: taggedMember.id,
        },
      ]),
    });
    this.setState({ showTagSuggestions: false, isMention: false });
    this.props.closeTagSuggestions();
  };
  sendMessage = (e) => {
    let tagList = [];
    let tagUsersList = [];
    this.state.taggedMembers.forEach((taggedMember) => {
      if (
        this.state.message.substring(
          taggedMember.start,
          taggedMember.end + 1
        ) === `@${taggedMember.fullName}`
      ) {
        tagList.push({
          end: taggedMember.end,
          fullName: taggedMember.fullName,
          start: taggedMember.start,
          userId: taggedMember.userId,
        });
        tagUsersList.push(taggedMember.userId);
      } else if (this.state.message.indexOf(taggedMember.fullName) > -1) {
        let start, end;
        start = this.state.message.indexOf(`@${taggedMember.fullName}`);
        end = start + taggedMember.fullName.length;
        tagList.push({
          end: end,
          fullName: taggedMember.fullName,
          start: start,
          userId: taggedMember.userId,
        });
        tagUsersList.push(taggedMember.userId);
      }
    });
    e.preventDefault();
    if (this.state.message !== "" && this.state.message.trim() !== "") {
      const urlMatches = this.state.message.match(/\b(http|https)?:\/\/\S+/gi);
      let post = {};
      if (this.props.type === "post") {
        post = {
          data: {
            isDeeplink: false,
            isRichLink: urlMatches ? true : false,
            taggedMembersList: tagList,
            text: this.state.message,
            source: "web",
            type: "TEXT",
          },
          group: this.props.selectedFeed?.id,
          tagged_users: tagUsersList,
          type: "text",
          impersonate_user_id: this.props.selectedSeedUser?.id,
          send_notification: false,
        };
      }
      if (this.props.type === "thread") {
        post = {
          data: {
            isDeeplink: false,
            isRichLink: urlMatches ? true : false,
            taggedMembersList: tagList,
            text: this.state.message,
            repliedPost: this.props.repliedPost,
            source: "web",
            type: "TEXT",
          },
          group: this.props.selectedFeed?.id,
          replied_to: this.props.repliedPost.id,
          tagged_users: tagUsersList,
          type: "text",
          impersonate_user_id: this.props.selectedSeedUser?.id,
          send_notification: false,
        };
      }

      this.props.postMessage(
        post,
        this.props.selectedFeed?.id,
        this.props.type
      );
    }
    this.setMessageText("");
    this.setState({ emojiPanel: false, taggedMembers: [] });

    Mixpanel.track("CHAT_GROUP_TEXT_SEND", {
      GROUP_ID: this.props.selectedFeed?.id,
      GROUP_NAME: this.props.selectedFeed?.name,
      SENDER: "web",
    });
  };
  emojiPanelOpenClose = () => {
    this.setState({ emojiPanel: !this.state.emojiPanel });
  };
  onEmojiClick = (event, emojiObject) => {
    let newMessage = this.state.message + emojiObject.emoji;
    this.setState({ message: newMessage });
  };

  toggleFooter = () => {
    this.setState({
      showFooter: !this.state.showFooter,
      blob: null,
      blobURL: "",
    });
  };
  clearPastedImagePreview = () => {
    this.setState({
      blob: null,
      blobURL: "",
      caption: "",
    });
  };
  handlePopoverClose = () => {
    this.setState({ showTagSuggestions: false, showFeedTagSuggestions: false });
    this.props.closeTagSuggestions();
  };
  filterTags = (matchingData) => {
    let filteredList = [];
    let term = matchingData;
    const { feedTagSuggestions, threadTagSuggestions } = this.props.tags;
    let tagList =
      this.props.type === "thread" ? threadTagSuggestions : feedTagSuggestions;

    tagList &&
      tagList.forEach((tag) => {
        if (tag?.full_name?.toLowerCase().indexOf(term.toLowerCase()) !== -1) {
          filteredList.push(tag);
        }
      });
    if (term !== "") {
      this.setState({ filteredList: filteredList });
    } else {
      this.setState({ filteredList: tagList });
    }
  };
  setFocusOnPaste = (e) => {
    this.captionInputRef.current.focus();
  };
  // HANDLE PASTE
  handlePaste = async (e) => {
    // Get the data of clipboard
    const clipboardItems = e.clipboardData.items;
    const items = [].slice.call(clipboardItems).filter(function(item) {
      // Filter the image items only
      return item.type.indexOf("image") !== -1;
    });
    if (items.length === 0) {
      return;
    }

    const item = items[0];
    // Get the blob of image
    const blob = await item.getAsFile();
    const blobURL = URL.createObjectURL(blob);
    this.setState({ blobURL: blobURL, blob: blob });
    this.setFocusOnPaste(e);
  };

  handleUpload = (imageBlob, fileName) => {
    const uploadTask = storage
      .child(`attachment/community/image/${fileName}`)
      .put(imageBlob);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        this.setState({ loading: true });
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .child(`attachment/community/image/${fileName}`)
          .getDownloadURL()
          .then((url) => {
            this.setState({ loading: false, url: url });
          });
      }
    );
  };

  handleSend = async (e, imageBlob) => {
    e.preventDefault();
    let { blobObject, caption, taggedMembers } = this.state;
    // upload
    let mediaFileName =
      this.props.selectedSeedUser?.id + "_" + new Date().getTime();
    // this.setState({ mediaFileName: mediaFileName });
    setTimeout(this.handleUpload(imageBlob, mediaFileName), 500);
    // Update
    let tagList = [];
    let tagUsersList = [];
    let urlMatches = "";
    taggedMembers.forEach((taggedMember) => {
      if (
        caption.substring(taggedMember.start, taggedMember.end + 1) ===
        `@${taggedMember.fullName}`
      ) {
        tagList.push({
          end: taggedMember.end,
          fullName: taggedMember.fullName,
          start: taggedMember.start,
          userId: taggedMember.userId,
        });
        tagUsersList.push(taggedMember.userId);
      } else if (caption.indexOf(taggedMember.fullName) > -1) {
        let start, end;
        start = caption.indexOf(`@${taggedMember.fullName}`);
        end = start + taggedMember.fullName.length;
        tagList.push({
          end: end,
          fullName: taggedMember.fullName,
          start: start,
          userId: taggedMember.userId,
        });
        tagUsersList.push(taggedMember.userId);
      }
    });
    if (caption !== "" && caption.trim() !== "") {
      urlMatches = caption.match(/\b(http|https)?:\/\/\S+/gi);
    }
    let post = {
      data: {
        isDeeplink: false,
        isRichLink: urlMatches ? true : false,
        taggedMembersList: tagList,
        size: blobObject?.size,
        caption: caption,
        // repliedPost: repliedPost,
        source: "web",
        type: "IMAGE",
        url: `attachment/community/image/${mediaFileName}`,
      },
      group: this.props.feedList.selectedFeed?.id,
      tagged_users: tagUsersList,
      type: "image",
      impersonate_user_id: this.props.selectedSeedUser?.id,
      send_notification: false,
    };
    this.props.postMessage(post, this.props.feedList?.selectedFeed?.id, "post");
    this.setState({ blobURL: "", blob: null, caption: "" });
  };

  render() {
    const tagSuggestionList = this.props.tags?.tagSuggestions;
    let {
      showTagSuggestions,
      showFeedTagSuggestions,
      term,
      filteredList,
      message,
      showFooter,
      emojiPanel,
      blobURL,
      blob,
      caption,
    } = this.state;
    let { type, repliedPost } = this.props;
    return (
      <>
        {isMobile() && showTagSuggestions && (
          <div className="tag__suggestions">
            {tagSuggestionList &&
              tagSuggestionList.map((tag) => (
                <Tag
                  key={tag?.id}
                  setTaggedMember={this.setTaggedMember}
                  tag={tag}
                />
              ))}
          </div>
        )}
        {showFeedTagSuggestions && (
          <FeedTagSuggestions
            term={term}
            showFeedTagSuggestions={showFeedTagSuggestions}
            setTaggedMember={this.setTaggedMember}
            inputRef={this.inputRef}
            handlePopoverClose={this.handlePopoverClose}
            type={type}
            filteredList={filteredList}
          />
        )}
        {type === "post" && !showFooter && (
          <div className="feed__footerButton">
            <IconButton onClick={this.toggleFooter}>
              <EditIcon />
            </IconButton>
          </div>
        )}
        <div
          className={`feed__footer ${!showFooter && "hide__footer"}`}
          ref={this.inputRef}
        >
          {type === "post" && (
            <IconButton onClick={this.toggleFooter}>
              <CloseIcon />
            </IconButton>
          )}
          <div className="feed__footerInput">
            {blobURL && (
              <PasteToPreview
                blob={blob}
                blobURL={blobURL}
                caption={caption}
                onChange={this.onChange}
                clearPastedImagePreview={this.clearPastedImagePreview}
                handleSend={this.handleSend}
                captionInputRef={this.captionInputRef}
              />
            )}
            <form>
              <textarea
                value={message}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                placeholder="Send a message.."
                onPaste={(e) => this.handlePaste(e)}
              />
              <IconButton onClick={this.sendMessage}>
                <SendIcon />
              </IconButton>
            </form>
          </div>
          <div className="feed__footerSeed">
            <div className="feed__footerIcons">
              <IconButton onClick={this.emojiPanelOpenClose}>
                <InsertEmoticonIcon />
              </IconButton>
              {emojiPanel && (
                <div className="message__footerEmoji">
                  <Picker
                    onEmojiClick={this.onEmojiClick}
                    skinTone={SKIN_TONE_MEDIUM_DARK}
                  />
                </div>
              )}
              <MediaUploads type={type} repliedPost={repliedPost} />
            </div>
          </div>
          {!isMobile() && (
            <Popover
              id=""
              open={this.state.showTagSuggestions}
              onClose={this.handlePopoverClose}
              anchorEl={this.inputRef.current}
              anchorOrigin={{
                vertical: "top",
                horizontal: type === "thread" ? "left" : 550,
              }}
              disableAutoFocus
              disableEnforceFocus
              transformOrigin={{
                vertical: "top",
                horizontal: type === "thread" ? "right" : "left",
              }}
              PaperProps={{
                style: { maxHeight: 350 },
              }}
            >
              {tagSuggestionList &&
                tagSuggestionList.map((tag) => (
                  <Tag
                    key={tag?.id}
                    setTaggedMember={this.setTaggedMember}
                    tag={tag}
                  />
                ))}
            </Popover>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedSeedUser: state.seedUsers?.selectedSeedUser,
  feedList: state.feedList,
  tags: state.tags,
});
export default connect(mapStateToProps, {
  postMessage,
  fetchTagSuggestions,
  closeTagSuggestions,
  fetchTagSuggestionsNew,
})(FeedResponse);
