import APIConfig from "../utils/APIConfig";
import { Get } from "../utils/api";
import {
  FEED_LOADING_STARTED,
  UPDATED_FEED,
  UPDATED_FEED_ON_LOAD_MORE,
  SET_SELECTED_FEED,
  FETCHED_SEED_MEMBERS,
  RESET_SEED_MEMBERS,
} from "../constants/types";
import { initClapCount, resetClapCount } from "./feed-clap-count.action";
import { initReplyCount, resetReplyCount } from "./feed-reply-count.action";
import { fetchFeedTagSuggestions } from "./update-tag-suggestion.action";
import { openNotificationThread } from "./notification-action";
import _ from "lodash";

export const initLoadingUser = () => (dispatch) => {
  dispatch({
    type: FEED_LOADING_STARTED,
    payload: true,
  });
};

export const updateFeed = (response, firstLoad, loadMore, scrollTo) => (
  dispatch,
  getState
) => {
  if (response?.posts) {
    let feed = response?.posts?.reverse();
    let hasMore = feed.length > 0 ? true : false;
    let { notifications } = getState();
    if (loadMore) {
      dispatch({
        type: UPDATED_FEED_ON_LOAD_MORE,
        feed: feed,
        scrollTo: scrollTo,
        hasMore: hasMore,
        page: response.page,
        session_id: response.session_id,
      });
    } else {
      dispatch({
        type: UPDATED_FEED,
        feed: feed,
        hasMore: hasMore,
        page: response.page,
        session_id: response.session_id,
        fetchedFirstLoad: firstLoad,
      });
    }
    dispatch(fetchFeedTagSuggestions(response?.posts));
    //open thread for Notifications
    if (notifications?.selectedNotification !== null) {
      dispatch(openNotificationThread());
    }
  } else {
    console.log("end of list");
  }
};

export const fetchFeed = (
  group_id,
  firstLoad,
  isNewPost = false,
  newPost = null
) => (dispatch) => {
  firstLoad && dispatch(initLoadingUser());
  firstLoad && dispatch(resetClapCount());
  firstLoad && dispatch(resetReplyCount());
  Get(`${APIConfig.GROUP_FEED}${group_id}/posts?page=0&session_id=0`)
    .then((response) => {
      if (isNewPost && !newPostSaved(response?.posts, newPost?.id)) {
        let updatedPostList = [newPost, ...response?.posts];
        let updatedRespone = { ...response, posts: updatedPostList };
        dispatch(updateFeed(updatedRespone, firstLoad));

        if (firstLoad) {
          //initialise reply count for each post in feed
          let replyCountList = initialReplyCount(updatedRespone.posts);
          dispatch(initReplyCount(replyCountList));

          //initialise clap count for each post in feed
          let clapCountList = initialClapCount(updatedRespone.posts);
          dispatch(initClapCount(clapCountList));
        }
      } else {
        dispatch(updateFeed(response, firstLoad));

        if (firstLoad) {
          //initialise reply count for each post in feed
          let replyCountList = initialReplyCount(response?.posts);
          dispatch(initReplyCount(replyCountList));

          //initialise clap count for each post in feed
          let clapCountList = initialClapCount(response?.posts);
          dispatch(initClapCount(clapCountList));
        }
      }
    })
    .catch((error) => console.error(error));

  //fetch group seed members
  firstLoad && !isNewPost && dispatch(fetchSeedMembers(group_id));
};

const newPostSaved = (feed, postId) => {
  for (let i = 0; i < feed.length; i++) {
    if (feed[i].id === postId) {
      return true;
    }
    return false;
  }
};
const initialReplyCount = (feed) => {
  let replyCountList = {};
  feed &&
    feed.length > 0 &&
    feed.forEach((post) => {
      replyCountList[post?.id] = post.reply_count || 0;
    });
  return replyCountList;
};

const initialClapCount = (feed) => {
  let clapCountList = {};
  feed &&
    feed.length > 0 &&
    feed.forEach((post) => {
      clapCountList[post?.id] = post.clap_count || 0;
    });
  return clapCountList;
};

export const fetchMoreFeed = (group_id, scrollTo) => (dispatch, getState) => {
  let { feedList } = getState();
  Get(
    `${APIConfig.GROUP_FEED}${group_id}/posts?page=${feedList &&
      feedList.page + 1}&session_id=${feedList && feedList.session_id}`
  )
    .then((response) => {
      dispatch(updateFeed(response, false, true, scrollTo));
      //initialise reply count for each post in feed
      let replyCountList = initialReplyCount(response?.posts);
      dispatch(initReplyCount(replyCountList));

      //initialise clap count for each post in feed
      let clapCountList = initialClapCount(response?.posts);
      dispatch(initClapCount(clapCountList));
    })
    .catch((error) => console.error(error));
};

export const fetchSeedMembers = (group_id) => (dispatch) => {
  dispatch(resetSeedMembers());
  Get(APIConfig.BASE_PATH + `v1/groups/${group_id}/seed_members`)
    .then(({ seed_members }) => {
      let seedMembersSorted = _.sortBy(seed_members, ["full_name"]);
      dispatch({
        type: FETCHED_SEED_MEMBERS,
        payload: seedMembersSorted,
      });
    })
    .catch((error) => console.error(error));
};

export const setSelectedFeed = (group, isNotification) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_FEED,
    payload: group,
  });
};

export const resetSeedMembers = () => (dispatch) => {
  dispatch({
    type: RESET_SEED_MEMBERS,
    payload: true,
  });
};
