import React from "react";
import { useDispatch } from "react-redux";
import { setGroupsMobileOpen } from "../../actions/auth.action";
import { setThreadClose } from "../../actions/create-new-post.action";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function FeedMenu() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleOpen = () => {
    dispatch(setGroupsMobileOpen());
    dispatch(setThreadClose());
  };
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      onClick={handleOpen}
      className={classes.menuButton}
    >
      <MenuIcon />
    </IconButton>
  );
}
