import {
  GROUP_LOADING_STARTED,
  UPDATE_GROUPS_LIST,
  INIT_GROUP_LAST_SEEN_AT,
  UPDATE_GROUP_LAST_SEEN_AT,
  FETCHED_GROUP_NOTIFICATION,
  RESET_UNREAD_COUNT,
} from "../constants/types";
import APIConfig from "../utils/APIConfig";
import { Get } from "../utils/api";
import { sortBy } from "../utils/formatDate";

// write basic action creators
export const initLoadingUser = () => (dispatch) => {
  dispatch({
    type: GROUP_LOADING_STARTED,
    payload: true,
  });
};

export const updateGroupList = (groupList) => (dispatch) => {
  if (groupList && Array.isArray(groupList)) {
    let list = groupList?.sort(sortBy("name"));
    dispatch({
      type: UPDATE_GROUPS_LIST,
      payload: list,
    });
    dispatch(updateNotification(list));
  }
};

export const initLastSeenAt = (groupList) => (dispatch) => {
  let d = new Date();

  let initTime = d.setHours(d.getHours() - 2);
  const data = {};
  groupList &&
    groupList.forEach((group) => {
      data[group.id] = { id: group.id, last_seen_at: initTime };
    });
  dispatch({
    type: INIT_GROUP_LAST_SEEN_AT,
    payload: data,
  });
};

export const updateLastSeenAt = (group_id) => (dispatch) => {
  dispatch({
    type: UPDATE_GROUP_LAST_SEEN_AT,
    payload: { id: group_id, last_seen_at: Date.now() },
  });
};

export const updateNotification = () => (dispatch, getState) => {
  const { groups } = getState();
  const groupsLastSeenAt = groups && groups.groupsLastSeenAt;
  let lastSeenData = [];
  for (let key in groupsLastSeenAt) {
    lastSeenData.push(groupsLastSeenAt[key]);
  }
  Get(
    `${APIConfig.GROUP_NOTIFICATION}?json_param=${encodeURIComponent(
      JSON.stringify(lastSeenData)
    )}`
  ).then((response) => {
    let unreadCount = {};
    response &&
      response.data &&
      response.data.forEach((group) => {
        unreadCount[group.id] = group.unread_count;
      });
    dispatch({
      type: FETCHED_GROUP_NOTIFICATION,
      payload: unreadCount,
    });
  });
};

export const resetUnreadCount = (id) => (dispatch) => {
  dispatch({
    type: RESET_UNREAD_COUNT,
    payload: id,
  });
};
