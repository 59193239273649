import {
  UPDATE_NOTIFICATIONS,
  SELECTED_NOTIFICATION,
  NOTIFICATION_COUNT_DECREASE,
} from "../constants/types";
import { Get, Post } from "../utils/api";
import APIConfig from "../utils/APIConfig";
import { setThreadOpen } from "./create-new-post.action";
import { fetchFeed, setSelectedFeed } from "./update-feed-action";
import {
  updateLastSeenAt,
  resetUnreadCount,
} from "./update-groups-list.action";
import { setSelectedSeedUser, setGroupsMobileClose } from "./auth.action";
import { closeFeedTagSuggestions } from "./update-tag-suggestion.action";

export const fetchNotifications = () => (disaptch) => {
  Get(APIConfig.NOTIFICATIONS)
    .then((res) => {
      const notifsList = res?.notifications;
      const taggedNotifList = notifsList?.filter(
        (notif) =>
          notif?.notif_type === "replied_to" ||
          notif?.notif_type === "tagged_community" ||
          notif?.notif_type === "tagged_in_reply" ||
          notif?.notif_type === "replied_in_thread"
      );
      let newCount = 0;
      taggedNotifList.forEach((notif) => {
        if (notif?.read_status === "unread") {
          newCount++;
        }
      });
      disaptch({
        type: UPDATE_NOTIFICATIONS,
        payload: taggedNotifList,
        newCount: newCount,
      });
    })
    .catch((error) => console.error(error));
};

export const setSelectedNotification = (notif) => (dispatch) => {
  dispatch({
    type: SELECTED_NOTIFICATION,
    payload: notif,
  });
};

export const openNotification = (notif) => (dispatch, getState) => {
  if (notif?.read_status === "unread") {
    dispatch(updateNotifReadStatus(notif?.id));
  }
  const notifInfo = notif?.data;
  const { auth, groups, feedList } = getState();
  const group = groups?.groupList?.filter(
    (group) => group?.id === notifInfo?.group_id
  );
  const currentGroup = group && group[0];
  const selectedFeed = feedList?.selectedFeed;
  dispatch(setSelectedNotification(notif));
  dispatch(closeFeedTagSuggestions());
  dispatch(setSelectedSeedUser(auth?.user));
  dispatch(setGroupsMobileClose());
  dispatch(updateLastSeenAt(notifInfo?.group_id));
  dispatch(resetUnreadCount(notifInfo?.group_id));

  if (
    (selectedFeed && selectedFeed?.id !== notifInfo?.group_id) ||
    !selectedFeed
  ) {
    dispatch(fetchFeed(notifInfo?.group_id, true));
    dispatch(setSelectedFeed(currentGroup, true));
  } else {
    dispatch(openNotificationThread());
  }
};
export const openNotificationThread = () => (dispatch, getState) => {
  const { feedList, notifications } = getState();
  const selectedFeed = feedList?.selectedFeed?.id;
  const feed = feedList?.feed;
  const notif = notifications?.selectedNotification?.data;
  const postId = notif?.post_id;
  const groupId = notif?.group_id;

  if (selectedFeed === groupId) {
    const post = feed?.filter((post) => post?.id === postId);
    const index = feed?.findIndex((post) => post?.id === postId);
    let currentPost = post && post[0];
    if (currentPost && index > -1) {
      dispatch(
        setThreadOpen({
          ...currentPost,
          postIndex: index,
        })
      );
    } else {
      dispatch(fetchPost(postId, groupId));
    }
  }
};

export const fetchPost = (postId, groupId) => (dispatch) => {
  Get(`${APIConfig.GROUP_FEED_POST}${groupId}/posts?pivot_id=${postId}`)
    .then((response) => {
      let postDetails = response && Array.isArray(response) && response[0];
      if (postDetails) {
        dispatch(
          setThreadOpen({
            ...postDetails,
            postIndex: null,
          })
        );
      }
    })
    .catch((error) => console.error(error));
};

export const closeNotification = () => (dispatch) => {
  dispatch({
    type: SELECTED_NOTIFICATION,
    payload: null,
  });
};

export const updateNotifReadStatus = (notifId) => (dispatch) => {
  let data = { notifications: [{ id: notifId, read_status: "read" }] };
  Post(APIConfig.NOTIFICATION_READ_STATUS, data).then((response) => {
    dispatch({
      type: NOTIFICATION_COUNT_DECREASE,
      payload: true,
    });
  });
};
