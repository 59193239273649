import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { ReactComponent as ReportIcon } from "../../assets/ReportGroup.svg";
import { ReactComponent as ArrowIcon } from "../../assets/Arrow.svg";
import "./ReportOptions.css";
import { isMobile } from "../../utils/formatDate";
import { REPORT_OPTIONS } from "../../constants/types";
import Mixpanel from "../../utils/Mixpanel";

const modalWidth = isMobile() ? "90%" : 450;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: "none",
    borderRadius: "4px",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: modalWidth,
    height: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    background: "#f97c7c",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 35,
    padding: "0 15px",
    marginLeft: "3px",
    marginRight: "3px",
    outline: "none",
    "&:hover": {
      backgroundColor: "#ee6969",
      boxShadow: "none",
    },
  },
  default: {
    background: "#e0e0e0",
    borderRadius: 3,
    border: 0,
    color: "black",
    height: 35,
    padding: "0 15px",
    marginLeft: "3px",
    marginRight: "3px",
    outline: "none",
    "&:hover": {
      backgroundColor: "#e1e1e1",
      boxShadow: "none",
    },
  },
  label: {
    fontWeight: 600,
    color: "white",
  },
  defaultLabel: {
    fontWeight: 600,
    color: "black",
  },
}));

const ReportOptions = ({
  isOpen,
  onClose = () => {},
  setReportOption = () => {},
  openReportMessageModal = () => {},
  type = "",
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const handleOptionClick = (option) => {
    setReportOption(option);
    openReportMessageModal();
    if (type === "user") {
      trackUserOptionClick(option);
    } else {
      trackPostOptionClick(option);
    }
  };

  const trackPostOptionClick = (option) => {
    Mixpanel.track("REPORT_POST_OPTION_SELECTED", {
      SOURCE: "1:1, Profile",
      OPTION: option?.title || "",
      TYPE: type === "post" ? "OM" : "EM",
      SENDER: "web",
    });
  };

  const trackUserOptionClick = (option) => {
    Mixpanel.track("REPORT_USER_OPTION_SELECTED", {
      SOURCE: "1:1, Profile",
      OPTION: option?.title || "",
      SENDER: "web",
    });
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div className="report">
          <div className="report__header">
            <ReportIcon />
            <div className="report__headerText">
              <b>Report this {type} anonymously</b>
              <p>Let's protect our Groups and make sure everyone feels safe.</p>
            </div>
          </div>
          <div className="report__option">
            {REPORT_OPTIONS.map((option, index) => {
              return (
                <div
                  key={index}
                  className="report__content"
                  onClick={() => handleOptionClick(option)}
                >
                  <div className="report__contentText">
                    <b>{option.title}</b>
                    <p>{option.description}</p>
                  </div>
                  <ArrowIcon />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportOptions;
